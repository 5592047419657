import React, { useEffect, useState } from "react";

function Header() {
  const [isSticky, setIsSticky] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);

  // Handle scroll event for sticky header
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 100) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  // Toggle mobile menu
  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };
  return (
    // <>
    //   <div className="loader-wrapper">
    //     <span className="loader"></span>
    //     <div className="loder-section left-section"></div>
    //     <div className="loder-section right-section"></div>
    //   </div>

    //   <div className="dark">
    //     <div className="dark-button">
    //       <input type="checkbox" className="toggle-switch" />
    //     </div>
    //   </div>

    //   <div className="header-area" id="sticky-header">
    //     <div className="container-fulid">
    //       <div className="row align-items-center">
    //         <div className="col-lg-3">
    //           <div className="header-logo">
    //             <a href="/">
    //               <img src="assets/images/home-1/logo-1.png" alt="" />
    //             </a>
    //           </div>
    //         </div>
    //         <div className="col-lg-6">
    //           <div className="header-menu">
    //             <ul>
    //               <li>
    //                 <a href="/">Home</a>
    //               </li>
    //               <li>
    //                 <a href="/rooms">Rooms</a>
    //               </li>
    //               <li className="menu-item-has-children">
    //                 <a href="#">
    //                   Services<i className="fas fa-chevron-down"></i>
    //                 </a>
    //                 <ul className="sub-menu">
    //                   <li>
    //                     <a href="service-detail">Gym Training Room</a>
    //                   </li>
    //                   <li>
    //                     <a href="service-detail">Intercontinental Restuarant</a>
    //                   </li>
    //                   <li>
    //                     <a href="service-detail">Spa</a>
    //                   </li>
    //                   <li>
    //                     <a href="service-detail">Swimming Pool</a>
    //                   </li>
    //                 </ul>
    //               </li>
    //               <li>
    //                 <a href="about">About</a>
    //               </li>
    //               <li>
    //                 <a href="contact">Contact</a>
    //               </li>
    //             </ul>
    //           </div>
    //         </div>
    //         <div className="col-lg-3">
    //           <div className="header-button text-right">
    //             <a href="find-room.html">Booking Online</a>
    //           </div>
    //         </div>
    //       </div>
    //     </div>
    //   </div>

    //   <div className="mobile-menu-area sticky d-sm-block d-md-block d-lg-none ">
    //     <div className="mobile-menu">
    //       <nav className="header-menu">
    //         <ul className="nav_scroll">
    //           <li>
    //             <a href="/">Home</a>
    //           </li>
    //           <li>
    //             <a href="/rooms">Rooms</a>
    //           </li>
    //           <li className="menu-item-has-children">
    //             <a href="#">
    //               Services<i className="fas fa-chevron-down"></i>
    //             </a>
    //             <ul className="sub-menu">
    //               <li>
    //                 <a href="service-detail">Gym Training Room</a>
    //               </li>
    //               <li>
    //                 <a href="service-detail">Intercontinental Restuarant</a>
    //               </li>
    //               <li>
    //                 <a href="service-detail">Spa</a>
    //               </li>
    //               <li>
    //                 <a href="service-detail">Swimming Pool</a>
    //               </li>
    //             </ul>
    //           </li>
    //           <li>
    //             <a href="about">About</a>
    //           </li>
    //           <li>
    //             <a href="contact">Contact</a>
    //           </li>
    //         </ul>
    //       </nav>
    //     </div>
    //   </div>
    // </>

    <>
      <div
        className={`header-area ${isSticky ? "sticky-header tw-bg-white" : ""}`}
      >
        <div className="container-fluid">
          <div className="row align-items-center">
            <div className="col-lg-3 col-md-6 col-6">
              <div className="header-logo">
                <a href="/">
                  <img
                    src="/assets/images/app-images/hillview-logo.png"
                    alt="Logo"
                    style={{ width: "121px", height: "52px" }}
                  />
                </a>
              </div>
            </div>

            {/* Desktop  Menu Section */}
            <div className="col-lg-6 d-none d-lg-block">
              <div className="header-menu">
                <ul style={{ color: "red" }}>
                  <li>
                    <a href="/">Home</a>
                  </li>
                  <li>
                    <a
                      href="/rooms"
                      style={{
                        color: isSticky ? "black" : "white",
                      }}
                    >
                      Rooms
                    </a>
                  </li>
                  <li className="menu-item-has-children">
                    <a
                      href="#"
                      style={{
                        color: isSticky ? "black" : "white",
                      }}
                    >
                      Services <i className="fas fa-chevron-down"></i>
                    </a>
                    <ul className="sub-menu">
                      <li>
                        <a href="/services/restaurant">Restaurant & Bar</a>
                      </li>
                      {/* <li>
                        <a href="/service-detail">Spa</a>
                      </li> */}
                      <li>
                        <a href="/services/swimming-pool">Swimming Pool</a>
                      </li>
                      {/* <li>
                        <a href="/services/gym">Gym Training Room</a>
                      </li> */}
                    </ul>
                  </li>
                  <li>
                    <a
                      href="/about"
                      style={{
                        color: isSticky ? "black" : "white",
                      }}
                    >
                      About
                    </a>
                  </li>
                  <li>
                    <a
                      href="/contact"
                      style={{
                        color: isSticky ? "black" : "white",
                      }}
                    >
                      Contact
                    </a>
                  </li>
                </ul>
              </div>
            </div>

            {/* Booking Button */}
            <div className="col-lg-3 col-md-6 col-6 text-right d-none d-lg-block">
              <div className="header-button">
                <a href="/find-room" className="btn-primary">
                  Booking Online
                </a>
              </div>
            </div>

            {/* Mobile Hamburger Menu */}
            <div className="col-6 d-lg-none text-right">
              <div
                className="mobile-menu-toggle"
                style={{ display: "flex", justifyContent: "end" }}
              >
                <button className="hamburger" onClick={toggleMenu}>
                  <span className="hamburger-line"></span>
                  <span className="hamburger-line"></span>
                  <span className="hamburger-line"></span>
                </button>
              </div>
            </div>
          </div>
        </div>

        {/* Mobile Menu */}
        {menuOpen && (
          <div className="mobile-menu sticky d-lg-none tw-w-full tw-h-[200px]  md:tw-h-[300px]">
            <nav className="header-menu">
              <div className="tw-flex tw-items-center tw-justify-between tw-px-6 tw-pt-4">
                <div>
                  <img
                    src="/assets/images/app-images/hillview-logo.png"
                    alt="Logo"
                    style={{ width: "121px", height: "52px" }}
                  />
                </div>
                <div>
                  <button
                    className="tw-bg-transparent tw-border-0 tw-text-black tw-cursor-pointer tw-text-lg"
                    onClick={() => {
                      setMenuOpen(false);
                    }}
                    aria-label="Close"
                  >
                    &times;{" "}
                  </button>
                </div>
              </div>
              <ul
                style={{
                  backgroundColor: "white",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <li>
                  <a href="/" style={{ color: "black" }}>
                    Home
                  </a>
                </li>
                <li>
                  <a href="/rooms" style={{ color: "black" }}>
                    Rooms
                  </a>
                </li>
                <li className="menu-item-has-children">
                  <a href="#" style={{ color: "black" }}>
                    Services <i className="fas fa-chevron-down"></i>
                  </a>
                  <ul className="sub-menu">
                    {/* <li>
                      <a href="/services/gym" style={{ color: "black" }}>
                        Gym Training Room
                      </a>
                    </li> */}
                    <li>
                      <a href="/services/restaurant" style={{ color: "black" }}>
                        Restaurant & Bar
                      </a>
                    </li>
                    {/* <li>
                      <a href="/services/spa" style={{ color: "black" }}>
                        Spa
                      </a>
                    </li> */}
                    <li>
                      <a
                        href="/services/swimming-pool"
                        style={{ color: "black" }}
                      >
                        Swimming Pool
                      </a>
                    </li>
                  </ul>
                </li>
                <li>
                  <a href="/about" style={{ color: "black" }}>
                    About
                  </a>
                </li>
                <li>
                  <a href="/contact" style={{ color: "black" }}>
                    Contact
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        )}
      </div>
    </>
  );
}

export default Header;
