import React from 'react'

function Contact() {
    return (
        <>
            <div className="breadcumb-area d-flex align-items-center">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-12">
                            <div className="breacumb-content">
                                <div className="breadcum-title">
                                    <h4>Contact</h4>
                                </div>
                                <ul>
                                    <li><a href="index-2.html">Home</a></li>
                                    <li>/</li>
                                    <li>Contact</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="contact-area style-two inner">
                <div className="container">
                    <div className="row add-backgroun">
                        <div className="col-lg-9">
                            <div className="section-title two">
                                <h4>Contact us</h4>
                                <h1>Contact With Us</h1>
                                <p className="section-desc-2">Rapidiously myocardinate cross-platform intellectual capital after the
                                    model. Appropriately create interactive infrastructures after maintance
                                    Holisticly facilitate stand-alone
                                </p>
                            </div>
                            <div className="single-contact-box">
                                <div className="contact-icon">
                                    <i className="bi bi-telephone-fill"></i>
                                </div>
                                <div className="contact-title">
                                    <h4>Call Us Now</h4>
                                    <p>+980 123 (4567) 890</p>
                                </div>
                            </div>
                            <div className="single-contact-box">
                                <div className="contact-icon">
                                    <i className="bi bi-envelope"></i>
                                </div>
                                <div className="contact-title">
                                    <h4>Sent Email</h4>
                                    <p>example@gmail.com</p>
                                </div>
                            </div>
                            <div className="single-contact-box">
                                <div className="contact-icon">
                                    <i className="bi bi-geo-alt-fill"></i>
                                </div>
                                <div className="contact-title">
                                    <h4>Our Locations</h4>
                                    <p>New elephant Road, Dhanmondi</p>
                                    <p>Dhaka - 1212</p>
                                </div>
                            </div>
                        </div>
                        {/* <div className="col-lg-6">
                          <form action="https://formspree.io/f/myyleorq" method="POST" id="dreamit-form">
                              <div className="single-contact-form">
                                  <div className="contact-content">
                                      <h4>Get In Touch</h4>
                                  </div>
                                  <div className="single-input-box">
                                      <input type="text" name="Name" placeholder="Your Name" required="">
                                  </div>
                                  <div className="single-input-box">
                                      <input type="text" name="Email" placeholder="Enter Your Email" required="">
                                  </div>
                                  <div className="single-input-box">
                                      <select name="Subject" id="Subject">
                                          <option value="saab">select Subject</option>
                                          <option value="opel">Luxury Hotel</option>
                                          <option value="audi">Room</option>
                                          <option value="audi">Hottel</option>
                                      </select>
                                  </div>
                                  <div className="single-input-box">
                                      <textarea name="Message" id="Message" placeholder="Write Message"></textarea>
                                  </div>
                                  <div className="single-input-box">
                                      <button type="submit">Sent Message</button>
                                  </div>
                              </div>
                          </form>
                          <div id="status"></div>
                      </div> */}
                    </div>
                </div>
            </div>

            <div className="google-map">
                <div className="row">
                    <div className="col-md-12">
                        <div className="google-map-content">
                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3021.0662009102393!2d-73.96815832342757!3d40.78255873329212!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c2589a018531e3%3A0xb9df1f7387a94119!2sCentral%20Park!5e0!3m2!1sen!2sbd!4v1697123394994!5m2!1sen!2sbd"></iframe>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Contact