import OwlCarousel from "react-owl-carousel";
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

function RoomDetail() {
    return (
        <>
            <div className="breadcumb-area d-flex align-items-center">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-12">
                            <div className="breacumb-content">
                                <div className="breadcum-title">
                                    <h4>Room Details</h4>
                                </div>
                                <ul>
                                    <li><a href="index-2.html">Home</a></li>
                                    <li>/</li>
                                    <li>Room Details</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="room-details">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8">
                            <div className="row">
                                <div className="room-details-list owl-carousel">
                                    <div className="col-lg-12">
                                        <div className="room-detils-thumb">
                                            <img src="assets/images/inner/room-details-1.jpg" alt="" />
                                        </div>
                                    </div>
                                    <div className="col-lg-12">
                                        <div className="room-detils-thumb">
                                            <img src="assets/images/inner/room-details-2.jpg" alt="" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="room-details-content">
                                        <h4>Luxury Room</h4>
                                        <h1>Delux Family Rooms</h1>
                                        <p className="room-detils-desc" data-cue="zoomIn">Rapidiously myocardinate cross-platform intellectual capital after
                                            marketing model. Appropriately create interactive infrastructures after maintainable are
                                            Holisticly facilitate stand-alone inframe extend state of the art benefits via web-enabled value.
                                            Completely fabricate extensible infomediaries rather than reliable e-services. Dramatically
                                            whiteboard alternative
                                        </p>

                                        <p className="room-detils-desc" data-cue="zoomIn">Conveniently fashion pandemic potentialities for team driven
                                            technologies.
                                            Proactively orchestrate robust systems rather than user-centric vortals. Distinctively seize
                                            top-line e-commerce with premier intellectual capital. Efficiently strategize goal-oriented
                                        </p>

                                        <div className="room-details-check-box" data-cue="zoomIn">
                                            <div className="room-details-check-content">
                                                <span><img src="assets/images/inner/room-details-1.png" alt="" />Check In</span>
                                                <p className="check-item"><i className="bi bi-check2"></i>Check-in from 9:00 AM - anytime</p>
                                                <p className="check-item"><i className="bi bi-check2"></i>Early check-in subject to availability</p>
                                            </div>
                                        </div>

                                        <div className="room-details-check-box upper" data-cue="zoomIn">
                                            <div className="room-details-check-content">
                                                <span><img src="assets/images/inner/room-details-2.png" alt="" />Check Out</span>
                                                <p className="check-item"><i className="bi bi-check2"></i>Check-out before noon</p>
                                                <p className="check-item"><i className="bi bi-check2"></i>Check-out from 9:00 AM - anytime</p>
                                            </div>
                                        </div>

                                        <h1 className="room-detils-title-2" data-cue="zoomIn">House Rules</h1>
                                        <p className="room-detils-desc upper" data-cue="zoomIn">Professionally deliver fully researched scenarios with turnkey communities.
                                            Competently unleash empowered applications without seamless data.
                                            Uniquely underwhelm quality outsourcing before transparent relationships.
                                            Efficiently enhance diverse relationships whereas leveraged</p>

                                        <h1 className="room-detils-title-2" data-cue="zoomIn">Childreen & Extra Beds</h1>
                                        <p className="room-detils-desc" data-cue="zoomIn">Applications without seamless data. Uniquely underwhelm quality outsourcing before
                                            transparent relationships. Efficiently enhance diverse relationships whereas leveraged new house cafe.</p>

                                        <div className="room-detls-list-item" data-cue="zoomIn">
                                            <ul>
                                                <li><i className="bi bi-check2"></i>Quickly generate bricks-and-clicks</li>
                                                <li><i className="bi bi-check2"></i>Interactively cultivate visionary platforms</li>
                                                <li><i className="bi bi-check2"></i>Energistically envisioneer resource</li>
                                                <li><i className="bi bi-check2"></i>Uniquely restore turnkey paradigms</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="booking-list">
                                        <div className="booking-list-content">
                                            <h4>Booking</h4>
                                        </div>
                                        <div className="booking-item">
                                            <ul>
                                                <li><strong>Check In -</strong>04 Oct, 2023</li>
                                                <li><strong>Check Out -</strong>10 Oct, 2023</li>
                                                <li><strong>Adult -</strong>02</li>
                                                <li><strong>Childreen -</strong>02</li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="room-details-amenities">
                                        <div className="room-details-amenities-content">
                                            <h4>Amenities</h4>
                                        </div>
                                        <div className="room-amenities-item">
                                            <ul>
                                                <li><img src="assets/images/inner/room-amenities-1.png" alt="" />2 - 5 Persons</li>
                                                <li><img src="assets/images/inner/room-amenities-2.png" alt="" />Free WiFi Available</li>
                                                <li><img src="assets/images/inner/room-amenities-3.png" alt="" />Swimingpools</li>
                                                <li><img src="assets/images/inner/room-amenities-4.png" alt="" />Breakfast</li>
                                                <li><img src="assets/images/inner/room-amenities-5.png" alt="" />250 SQFT Rooms</li>
                                                <li><img src="assets/images/inner/room-amenities-6.png" alt="" />Gym facilities</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="room-area inner two">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 text-center">
                            <div className="section-title center" data-cue="zoomIn">
                                <div className="section-thumb">
                                    <img src="assets/images/home-1/section-shape1.png" alt="" />
                                </div>
                                <h1>HillView’s Rooms & Suites</h1>
                                <p className="section-desc-1">Proactively morph optimal infomediaries rather than accurate expertise. Intrinsicly
                                    progressive resources rather than resource-leveling</p>
                            </div>
                        </div>
                    </div>
                    <div className="row" data-cue="zoomIn">
                        <div className="room_list owl-carousel">
                            <div className="col-lg-12">
                                <div className="room-single-box">
                                    <div className="room-thumb">
                                        <img src="assets/images/home-1/room-1.jpg" alt="" />
                                        <div className="room-details-button">
                                            <a href="#">View Details<i className="bi bi-arrow-right"></i></a>
                                        </div>
                                    </div>
                                    <div className="room-pricing">
                                        <span className="dolar">$560</span>
                                        <span>Night</span>
                                    </div>
                                    <div className="room-content">
                                        <h4>Luxury Room</h4>
                                        <a href="#">Delux Family Rooms</a>
                                        <p>1500 SQ.FT/Rooms</p>
                                    </div>
                                    <div className="room-bottom">
                                        <div className="room-bottom-icon">
                                            <span><img src="assets/images/home-1/room-bottom-icon.png" alt="" />2 King Bed</span>
                                        </div>
                                        <div className="coustomar-rating">
                                            <ul>
                                                <li><i className="bi bi-star-fill"></i></li>
                                                <li><i className="bi bi-star-fill"></i></li>
                                                <li><i className="bi bi-star-fill"></i></li>
                                                <li><i className="bi bi-star-fill"></i></li>
                                                <li><i className="bi bi-star-half"></i></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-12">
                                <div className="room-single-box">
                                    <div className="room-thumb">
                                        <img src="assets/images/home-1/room-2.jpg" alt="" />
                                        <div className="room-details-button">
                                            <a href="#">View Details<i className="bi bi-arrow-right"></i></a>
                                        </div>
                                    </div>
                                    <div className="room-pricing">
                                        <span className="dolar">$560</span>
                                        <span>Night</span>
                                    </div>
                                    <div className="room-content">
                                        <h4>Luxury Room</h4>
                                        <a href="#">Doubble Suite Rooms</a>
                                        <p>1500 SQ.FT/Rooms</p>
                                    </div>
                                    <div className="room-bottom">
                                        <div className="room-bottom-icon">
                                            <span><img src="assets/images/home-1/room-bottom-icon.png" alt="" />2 King Bed</span>
                                        </div>
                                        <div className="coustomar-rating">
                                            <ul>
                                                <li><i className="bi bi-star-fill"></i></li>
                                                <li><i className="bi bi-star-fill"></i></li>
                                                <li><i className="bi bi-star-fill"></i></li>
                                                <li><i className="bi bi-star-fill"></i></li>
                                                <li><i className="bi bi-star-fill"></i></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-12">
                                <div className="room-single-box">
                                    <div className="room-thumb">
                                        <img src="assets/images/home-1/room-3.jpg" alt="" />
                                        <div className="room-details-button">
                                            <a href="#">View Details<i className="bi bi-arrow-right"></i></a>
                                        </div>
                                    </div>
                                    <div className="room-pricing">
                                        <span className="dolar">$560</span>
                                        <span>Night</span>
                                    </div>
                                    <div className="room-content">
                                        <h4>Luxury Room</h4>
                                        <a href="#">Suprior Bed Rooms</a>
                                        <p>1500 SQ.FT/Rooms</p>
                                    </div>
                                    <div className="room-bottom">
                                        <div className="room-bottom-icon">
                                            <span><img src="assets/images/home-1/room-bottom-icon.png" alt="" />2 King Bed</span>
                                        </div>
                                        <div className="coustomar-rating">
                                            <ul>
                                                <li><i className="bi bi-star-fill"></i></li>
                                                <li><i className="bi bi-star-fill"></i></li>
                                                <li><i className="bi bi-star-fill"></i></li>
                                                <li><i className="bi bi-star-fill"></i></li>
                                                <li><i className="bi bi-star"></i></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-12">
                                <div className="room-single-box">
                                    <div className="room-thumb">
                                        <img src="assets/images/home-1/room-1.jpg" alt="" />
                                        <div className="room-details-button">
                                            <a href="#">View Details<i className="bi bi-arrow-right"></i></a>
                                        </div>
                                    </div>
                                    <div className="room-pricing">
                                        <span className="dolar">$560</span>
                                        <span>Night</span>
                                    </div>
                                    <div className="room-content">
                                        <h4>Luxury Room</h4>
                                        <a href="#">Delux Family Rooms</a>
                                        <p>1500 SQ.FT/Rooms</p>
                                    </div>
                                    <div className="room-bottom">
                                        <div className="room-bottom-icon">
                                            <span><img src="assets/images/home-1/room-bottom-icon.png" alt="" />2 King Bed</span>
                                        </div>
                                        <div className="coustomar-rating">
                                            <ul>
                                                <li><i className="bi bi-star-fill"></i></li>
                                                <li><i className="bi bi-star-fill"></i></li>
                                                <li><i className="bi bi-star-fill"></i></li>
                                                <li><i className="bi bi-star-fill"></i></li>
                                                <li><i className="bi bi-star-half"></i></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="brand-area" data-cue="zoomIn">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4"></div>
                        <div className="col-lg-8">
                            <div className="row">
                                <div className="brand-list owl-carousel">
                                    <div className="col-lg-12">
                                        <div className="single-brand-box">
                                            <div className="brand-thumb">
                                                <img src="assets/images/home-1/brand-1.png" alt="" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-12">
                                        <div className="single-brand-box">
                                            <div className="brand-thumb">
                                                <img src="assets/images/home-1/brand-2.png" alt="" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-12">
                                        <div className="single-brand-box">
                                            <div className="brand-thumb">
                                                <img src="assets/images/home-1/brand-3.png" alt="" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default RoomDetail