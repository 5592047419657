import React from "react";
import RestaurantBg from "../../assets/restaurant-bg.png";
import restaurant0 from "../../../public/assets/images/restaurant/restaurant-0.jpg";
import Pool from "../../assets/pool.png";
import Yoga from "../../assets/yoga.png";
import PersonalTraining from "../../assets/personal-training.png";
import exercise from "../../assets/exercise.png";
import family from "../../assets/happy.png";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

function Restaurant() {
  return (
    <div className="">
      <div
        className="tw-flex tw-items-center tw-justify-center tw-bg-cover tw-bg-center tw-text-red-400 tw-h-screen"
        style={{
          backgroundImage: `url("/assets/images/restaurant/restaurant-1.jpg")`,
        }}
      >
        <div className="hero-content tw-text-center">
          <h4>Resturant</h4>
          <h1>Dine With Us, Check Out Our </h1>
          <h1>Trending Menu</h1>
        </div>
      </div>

      <div className="service-details">
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              <div className="service-details-thumb">
                <img src="assets/images/inner/service-details.jpg" alt="" />
              </div>
            </div>
            <div className="col-lg-4">
              <div className="service-time-list">
                <div className="service-time-list-content">
                  <h4>Hours</h4>
                </div>
                <div className="service-time-list-item">
                  <ul>
                    <li><strong>Breakfast -</strong>7.00 AM to 10.30 AM</li>
                    <li><strong>Lunch -</strong>1.00 PM to 2.30 PM</li>
                    <li><strong>Supper -</strong>6.00 PM to 7.00 PM</li>
                    <li><strong>Dinner -</strong> 8.30 PM to 10.00 PM</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <div className="service-details-content">
                <div className="services-details-title-box">
                  <h4>Foods</h4>
                  <h1>The Restaurent Center</h1>
                  <p className="" >Rapidiously myocardinate cross-platform intellectual capital after marketing model. Appropriately create
                    interactive infrastructures after maintainable are Holisticly
                    facilitate stand-alone inframe extend state of the art benefits via web-enabled value.
                    Completely fabricate extensible infomediaries rather than reliable e-services.
                    Dramatically whiteboard alternative Conveniently fashion pandemic potentialities
                    for team driven technologies. Proactively orchestrate robust systems rather than
                    user-centric vortals. Distinctively seize top-line e-commerce with premier.
                  </p>
                </div>
                <div className="services-details-title-box">
                  <h2>Restaurent Rules</h2>
                  <p>Professionally deliver fully researched scenarios with turnkey communities. Competently unleash empowered
                    applications without seamless data. Uniquely under quality outsourcing before transparent relationships.
                    Efficiently enhance diverse relationships whereas leveraged leverage existing
                    just in time architectures before economically sound systems. Conveniently administrate
                  </p>
                </div>
                <div className="services-details-title-box">
                  <h2>Dress Code</h2>
                  <p>Professionally deliver fully researched scenarios with turnkey communities competently</p>
                  <div className="service-details-item">
                    <ul>
                      <li><i className="bi bi-check2"></i>Quickly generate bricks-and-clicks</li>
                      <li><i className="bi bi-check2"></i>Interactively cultivate visionary platforms</li>
                      <li><i className="bi bi-check2"></i>Energistically envisioneer resource</li>
                    </ul>
                  </div>
                </div>
                <div className="services-details-title-box">
                  <h2>Terrace</h2>
                  <p>Open the drinks only maintain restaurent rules and regulations below</p>
                  <div className="service-details-item">
                    <ul>
                      <li><i className="bi bi-check2"></i>Quickly generate bricks-and-clicks</li>
                      <li><i className="bi bi-check2"></i>Interactively cultivate visionary platforms</li>
                      <li><i className="bi bi-check2"></i>Energistically envisioneer resource</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="tw-flex tw-flex-col md:tw-flex-row tw-gap-2 tw-items-center tw-w-full tw-h-[40vh] tw-bg-black tw-mt-12">
        <div className="tw-flex-1 tw-h-full">
          <img
            src="/assets/images/restaurant/restaurant-0.jpg"
            alt="Lady"
            className="tw-h-full tw-w-full tw-object-cover"
          />
        </div>
        <div className="tw-flex-1 tw-h-full">
          <img
            src="/assets/images/restaurant/restaurant-1.jpg"
            alt="Exercise"
            className="tw-h-full tw-w-full tw-object-cover"
          />
        </div>
        <div className="tw-flex-1 tw-h-full">
          <img
            src="/assets/images/restaurant/restaurant-2.jpg"
            alt="Yoga"
            className="tw-h-full tw-w-full tw-object-cover"
          />
        </div>
      </div>

      <div className="tw-flex tw-flex-col md:tw-flex-row tw-gap-2 tw-items-center tw-w-full tw-h-[40vh] tw-bg-black">
        <div className="tw-flex-1 tw-h-full">
          <img
            src="/assets/images/restaurant/restaurant-3.jpg"
            alt="Lady"
            className="tw-h-full tw-w-full tw-object-cover"
          />
        </div>
        <div className="tw-flex tw-h-full">
          <img
            src="/assets/images/restaurant/restaurant-4.jpg"
            alt="Exercise"
            className="tw-h-full tw-w-full tw-object-cover"
          />
        </div>
        <div className="tw-flex-1 tw-h-full">
          <img
            src="/assets/images/restaurant/restaurant-5.jpg"
            alt="Yoga"
            className="tw-h-full tw-w-full tw-object-cover"
          />
        </div>
      </div>

      <div className="brand-area" data-cue="zoomIn">
        <div className="container">
          <div className="row">
            <div className="col-lg-4"></div>
            <div className="col-lg-8">
              <div className="row">
                <div className="brand-list owl-carousel">
                  <OwlCarousel
                    items={3}
                    responsive={{
                      0: {
                        items: 1, // 1 item per view on mobile
                      },
                      768: {
                        items: 3, // 3 items per view on larger screens
                      },
                    }}
                  >
                    <div className="col-lg-12">
                      <div className="single-brand-box">
                        <div className="brand-thumb">
                          <img src="assets/images/home-1/brand-1.png" alt="" />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="single-brand-box">
                        <div className="brand-thumb">
                          <img src="assets/images/home-1/brand-2.png" alt="" />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="single-brand-box">
                        <div className="brand-thumb">
                          <img src="assets/images/home-1/brand-3.png" alt="" />
                        </div>
                      </div>
                    </div>
                  </OwlCarousel>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Restaurant;
