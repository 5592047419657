import React from "react";
import Logo from "../assets/logo-2.png";

function Footer() {
  const currentYear = new Date().getFullYear();
  return (
    <>
      <div
        className="footer-area home-three"
        data-cue="zoomIn"
        style={{ opacity: "unset" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-6 col-sm-6">
              <div className="footer-widget-logo">
                <a href="index-2.html">
                  <img
                    src={Logo}
                    alt=""
                    className="tw-w-[300px] tw-h-[124px] tw-object-contain"
                    // style={{ width: "300px", height: "124px" }}
                  />
                </a>
              </div>
              <div className="footer-desc">
                <p className="tw-text-white">
                  For your comfort and convenience; luxury redefined.
                </p>
              </div>
              <div className="footer-widget-social-icon">
                <ul>
                  <li>Follow Us:</li>
                  <li>
                    <a
                      href="https://www.facebook.com/profile.php?id=61566447356084"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <i className="fab fa-facebook-f"></i>
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.instagram.com/hillviewapartment1806?igsh=MTB1NHpqaWZxYjloZg=="
                      target="_blank"
                      rel="noreferrer"
                    >
                      <i className="fab fa-instagram"></i>
                    </a>
                  </li>
                  {/* <li>
                    <i className="fab fa-twitter"></i>
                  </li> */}
                </ul>
              </div>
            </div>
            <div className="col-lg-2 col-md-6 col-sm-6">
              <div className="footer-widget-content">
                <div className="footer-widget-title">
                  <h4>Help</h4>
                </div>
                <div className="footer-widget-menu">
                  <ul>
                    <li>
                      <a href="#">Careers</a>
                    </li>
                    <li>
                      <a href="#">Cookie Policy</a>
                    </li>
                    <li>
                      <a href="#">Website Feedback</a>
                    </li>
                    <li>
                      <a href="#">Service Guarantee </a>
                    </li>
                    <li>
                      <a href="#">Insights</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-2 col-md-6 col-sm-6">
              <div className="footer-widget-content">
                <div className="footer-widget-title">
                  <h4>Our Services</h4>
                </div>
                <div className="footer-widget-menu">
                  <ul>
                    <li>
                      <a href="#">Store Directory</a>
                    </li>
                    <li>
                      <a href="#">Top Hotels</a>
                    </li>
                    <li>
                      <a href="#">Quick Links</a>
                    </li>
                    <li>
                      <a href="#">Important Links </a>
                    </li>
                    <li>
                      <a href="#">Insights</a>
                    </li>
                    <li>
                      <a href="#">Knowledge Center </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-6">
              <div className="footer-widget-content">
                <div className="footer-widget-title">
                  <h4>Get In Touch</h4>
                </div>
                <div className="footer-widget-contact">
                  <div className="footer-widget-contact-box">
                    <div className="footer-widget-contact-icon">
                      <i className="bi bi-geo-alt-fill"></i>
                    </div>
                    <div className="footer-widget-contact-content">
                      <h4>Location:</h4>
                      <p>
                        Plot 1806, Road 1, zone E,
                        <br /> Apo Resettlement Abuja FCT
                      </p>
                    </div>
                  </div>
                  <div className="footer-widget-contact-box">
                    <div className="footer-widget-contact-icon">
                      <i className="bi bi-chat"></i>
                    </div>
                    <div className="footer-widget-contact-content">
                      <h4>Email:</h4>
                      <p>hillviewapartment1806@gmail.com</p>
                    </div>
                  </div>
                  <div className="footer-widget-contact-box">
                    <div className="footer-widget-contact-icon">
                      <i className="bi bi-telephone"></i>
                    </div>
                    <div className="footer-widget-contact-content">
                      <h4>Phone:</h4>
                      <p>08095777444</p>
                      <p>08096777444</p>
                      <p>07073431162</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row add-border">
            <div className="col-lg-12">
              <div className="footer-bottom-content">
                <p>HillView Apartments @{currentYear} </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Footer;
