import React from "react";
import SwimmingBg from "../../assets/swimming-pool-bg.png";
import ArtPool from "../../assets/art-pool.png";
import Resort from "../../assets/resort.png";
import Flex from "../../assets/flex.png";
import Palm from "../../assets/palm.png";
import PoolAmenities from "../../assets/pool-amenities.png";
import PoolDesign from "../../assets/pool-design.png";
import SpecialFeatures from "../../assets/special-features.png";
import PoolRules from "../../assets/pool-rules.png";
import Dive from "../../assets/div.png";
import DressCode from "../../assets/dress-code.png";
import Running from "../../assets/running.png";
import Supervision from "../../assets/supervision.png";
import Ship from "../../assets/ship.png";
import Juice from "../../assets/juice.png";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

function SwimmingPool() {
  return (
    <div className="tw-bg-white">
      <div
        className="tw-flex tw-items-center tw-justify-center tw-bg-cover tw-bg-center tw-text-red-400 tw-h-screen"
        style={{
          backgroundImage: `url(${SwimmingBg})`,
        }}
      >
        <div className="hero-content tw-text-center">
          <h4>POOL FACILITIES</h4>
          <h1>Enjoy Complete & Best Quality</h1>
          <h1>Swimming Pool Facilities</h1>
        </div>
      </div>

      <div className="tw-mt-16">
        <h4 className="tw-text-center">OPERATING HOURS</h4>
        <p className="tw-text-center">
          Our luxurious swimming pool is open daily from 7:00 AM to 10:00 PM,
          offering a<br /> tranquil escape throughout the day and into the
          evening.
        </p>
      </div>

      <div className="tw-flex tw-flex-col md:tw-flex-row tw-justify-center tw-gap-12 md:tw-gap-24 tw-items-center tw-pt-24">
        <div>
          <img
            src={PoolDesign}
            alt=""
            className="tw-max-w-full tw-h-auto  tw-object-contain"
          />
        </div>
        <div>
          <h1 className="tw-mb-8 tw-text-3xl tw-text-[#02698A] tw-font-bold">
            Pool Design and Features
          </h1>

          <p className="tw-text-black tw-text-sm tw-leading-[30px]">
            Experience our state-of-the-art Infinity Pool that offers
            breathtaking views of the city
            <br /> skyline. Our pool spans 25 meters in length, with varying
            depths from 1 meter to 2.5
            <br /> meters, ideal for both leisurely swims and more vigorous
            laps. It is also maintained at
            <br /> a comfortable temperature of 28°C (82°F) year-round.
          </p>
        </div>
      </div>

      <div className="tw-flex tw-flex-col md:tw-flex-row-reverse tw-justify-center tw-gap-12 md:tw-gap-24 tw-items-center tw-pt-24">
        <div>
          <img
            src={PoolAmenities}
            alt=""
            className="tw-max-w-full tw-h-auto  tw-object-contain"
          />
        </div>
        <div>
          <h1 className="tw-mb-8 tw-text-3xl tw-text-[#002257] tw-font-bold">
            Pool Amenities
          </h1>
          <p className="tw-text-black tw-text-sm tw-leading-[30px]">
            Relax on our plush sun-beds, available on a first-come, first-served
            basis.
            <br /> Complimentary towels and robes are available poolside. Simply
            ask a staff
            <br /> member, or use our app to request additional linens.
            Umbrellas are also available
            <br /> throughout the pool area to provide shade and comfort during
            sunny days.
          </p>
        </div>
      </div>

      <div className="tw-flex tw-flex-col md:tw-flex-row tw-justify-center tw-gap-12 md:tw-gap-24 tw-items-center tw-pt-24">
        <div>
          <img
            src={SpecialFeatures}
            alt=""
            className="tw-max-w-full tw-h-auto  tw-object-contain"
          />
        </div>
        <div>
          <h1 className="tw-mb-8 tw-text-3xl tw-text-[#C35200] tw-font-bold">
            Special Features
          </h1>

          <p className="tw-text-black tw-text-sm tw-leading-[30px]">
            Unwind in our poolside Jacuzzi, with powerful jets designed to
            soothe tired muscles.
            <br /> Perfect for post-swim relaxation.
            <br /> Visit our Swim-Up Bar that offers a selection of refreshing
            cocktails, juices,
            <br /> and snacks. Enjoy a drink without leaving the water.
            <br />
            <span className="tw-mt-6">
              We have also got your children covered as they can enjoy our
              dedicated Children&apos;s
              <br /> Pool, with shallow waters and fun features like water
              fountains and splash zones.
            </span>
          </p>
        </div>
      </div>

      <div className="tw-flex tw-flex-col md:tw-flex-row-reverse tw-justify-center tw-gap-12 md:tw-gap-24 tw-items-center tw-pt-24">
        <div>
          <img
            src={PoolDesign}
            alt=""
            className="tw-max-w-full tw-h-auto  tw-object-contain"
          />
        </div>
        <div>
          <h1 className="tw-mb-8 tw-text-3xl tw-text-[#BA266D] tw-font-bold">
            Accessibility
          </h1>

          <p className="tw-text-black tw-text-sm tw-leading-[30px]">
            Our pool is fully accessible, with
            <br /> ramps and a pool lift available for guests with mobility
            needs.
            <br /> We have spacious and well-equipped changing rooms are
            available, complete with
            <br /> showers and lockers.
          </p>
        </div>
      </div>

      <div className="tw-flex tw-items-center tw-gap-12 tw-justify-center tw-mt-24">
        <div>
          <img src={PoolRules} alt="" />
        </div>
        <div>
          <h4>POOL GUIDELINES</h4>
          <h1>Safety Rules, Dress Codes and Sun Protection Tips</h1>
          <div className="mt-4 tw-flex  tw-gap-6">
            <div>
              <img src={Dive} alt="" className="tw-w-[46px] tw-h-[46px]" />
            </div>
            <div className="tw-flex tw-flex-col tw-gap-2">
              <h4 className="tw-font-semibold">
                <span className="tw-text-[#EC3B50]">NO</span> DIVING
              </h4>
              <p className="tw-text-[#202020] tw-text-sm tw-leading-6">
                The pool is not deep enough for diving. Diving may result in
                <br />
                serious injury. Please use caution.
              </p>
            </div>
          </div>

          <div className="mt-4 tw-flex tw-gap-6">
            <div>
              <img src={Running} alt="" className="tw-w-[46px] tw-h-[46px]" />
            </div>
            <div className="tw-flex tw-flex-col tw-gap-2">
              <h4 className="tw-font-semibold">
                <span className="tw-text-[#EC3B50]">NO</span> RUNNING
              </h4>
              <p className="tw-text-[#202020] tw-text-sm tw-leading-6">
                Children under 12 must be accompanied by an adult at all times.
                <br />
                Lifeguards are on duty, but personal supervision is essential.
              </p>
            </div>
          </div>

          <div className="mt-4 tw-flex  tw-gap-6">
            <div>
              <img
                src={Supervision}
                alt=""
                className="tw-w-[46px] tw-h-[46px]"
              />
            </div>
            <div className="tw-flex tw-flex-col tw-gap-2">
              <h4 className="tw-font-semibold">
                <span className="tw-text-black">SUPERVISION</span>
              </h4>
              <p className="tw-text-[#202020] tw-text-sm tw-leading-6">
                The pool is not deep enough for diving. Diving may result in
                <br />
                serious injury. Please use caution.
              </p>
            </div>
          </div>

          <div className="mt-4 tw-flex  tw-gap-6">
            <div>
              <img src={DressCode} alt="" className="tw-w-[46px] tw-h-[46px]" />
            </div>
            <div className="tw-flex tw-flex-col tw-gap-2">
              <h4 className="tw-font-semibold">DRESS CODE</h4>
              <p className="tw-text-[#202020] tw-text-sm tw-leading-6">
                Please wear appropriate swimwear. Bikinis, one-piece swimsuits,
                <br />
                and swim trunks are acceptable. No jeans, non-swimwear
                <br /> shorts, or regular clothing allowed. No sharp objects,
                jewelries or
                <br /> anything that can damage the pool.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="tw-flex tw-flex-col md:tw-flex-row tw-gap-4 tw-items-center tw-w-full tw-h-[40vh] tw-bg-white tw-mt-12">
        <div className="tw-flex-1 tw-h-full">
          <img
            src={ArtPool}
            alt="ArtPool"
            className="tw-h-full tw-w-full tw-object-cover"
          />
        </div>
        <div className="tw-flex-1 tw-h-full">
          <img
            src={Ship}
            alt="Exercise"
            className="tw-h-full tw-w-full tw-object-cover"
          />
        </div>
        <div className="tw-flex-1 tw-h-full">
          <img
            src={Resort}
            alt="Yoga"
            className="tw-h-full tw-w-full tw-object-cover"
          />
        </div>
      </div>

      <div className="tw-flex tw-flex-col md:tw-flex-row tw-gap-4 tw-items-center tw-w-full tw-h-[40vh] tw-bg-white tw-mt-6">
        <div className="tw-flex-1 tw-h-full">
          <img
            src={Flex}
            alt="Lady"
            className="tw-h-full tw-w-full tw-object-cover"
          />
        </div>
        <div className="tw-flex tw-h-full">
          <img
            src={Juice}
            alt="Exercise"
            className="tw-h-full tw-w-full tw-object-cover"
          />
        </div>
        <div className="tw-flex-1 tw-h-full">
          <img
            src={Palm}
            alt="Yoga"
            className="tw-h-full tw-w-full tw-object-cover"
          />
        </div>
      </div>

      <div className="brand-area" data-cue="zoomIn">
        <div className="container">
          <div className="row">
            <div className="col-lg-4"></div>
            <div className="col-lg-8">
              <div className="row">
                <div className="brand-list owl-carousel">
                  <OwlCarousel
                    items={3}
                    responsive={{
                      0: {
                        items: 1, // 1 item per view on mobile
                      },
                      768: {
                        items: 3, // 3 items per view on larger screens
                      },
                    }}
                  >
                    <div className="col-lg-12">
                      <div className="single-brand-box">
                        <div className="brand-thumb">
                          <img src="assets/images/home-1/brand-1.png" alt="" />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="single-brand-box">
                        <div className="brand-thumb">
                          <img src="assets/images/home-1/brand-2.png" alt="" />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="single-brand-box">
                        <div className="brand-thumb">
                          <img src="assets/images/home-1/brand-3.png" alt="" />
                        </div>
                      </div>
                    </div>
                  </OwlCarousel>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SwimmingPool;
