import React from 'react'

function About() {
    return (
        <>
            <div className="breadcumb-area d-flex align-items-center">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-12">
                            <div className="breacumb-content">
                                <div className="breadcum-title">
                                    <h4>About Us</h4>
                                </div>
                                <ul>
                                    <li><a href="index-2.html">Home</a></li>
                                    <li>/</li>
                                    <li>About</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="about-area inner">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6">
                            <div className="about-thumb">
                                <img src="assets/images/hotel/reception-1.jpg" style={{ width: '566px', height: '585px' }} alt="" />
                            </div>
                        </div>
                        <div className="col-lg-6  upper">
                            <div className="section-title two">
                                <h4>Luxury Hotel And Resort</h4>
                                <h1>Luxury Best Hotel In City</h1>
                                <h1>Californta, Usa</h1>
                                <p className="section-desc-2">Rapidiously myocardinate cross-platform intellectual capital after marketing
                                    model. Appropriately create interactive infrastructures after maintainable are
                                    Holisticly facilitate stand-alone inframe
                                </p>
                                <p className="about-inner-desc">Rapidiously myocardinate cross-platform intellectual capital after marketing
                                    model. Appropriately create interactive infrastructures after</p>
                            </div>
                            <div className="about-address">
                                <p>102/B, Dream Street, New Elephant Road, Resote Dhanmondi, Dhaka - 1212</p>
                            </div>
                            <div className="luxury-button">
                                <a href="#">About More</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="facilities-area home-three">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 text-center">
                            <div className="section-title center" data-cue="zoomIn">
                                <h4>Resote Services</h4>
                                <h1>Most Popular Hotel Facilities</h1>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-4 col-md-6">
                            <div className="single-facilities-box" data-cue="zoomIn">
                                <div className="facilities-icon">
                                    <img src="assets/images/home3/facilities-icon-1.png" alt="" />
                                </div>
                                <div className="facilities-content">
                                    <h4>Airport Transfer</h4>
                                    <p>Located Time Square, Apartment Hotel Private Retreat The Heart World Most Iconic Apartment Services experienced </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="single-facilities-box" data-cue="zoomIn">
                                <div className="facilities-icon">
                                    <img src="assets/images/home3/facilities-icon-2.png" alt="" />
                                </div>
                                <div className="facilities-content">
                                    <h4>Fibre Internet</h4>
                                    <p>Located Time Square, Apartment Hotel Private Retreat The Heart World Most Iconic Apartment Services experienced </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="single-facilities-box" data-cue="zoomIn">
                                <div className="facilities-icon">
                                    <img src="assets/images/home3/facilities-icon-3.png" alt="" />
                                </div>
                                <div className="facilities-content">
                                    <h4>Swimming Pool</h4>
                                    <p>Located Time Square, Apartment Hotel Private Retreat The Heart World Most Iconic Apartment Services experienced </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="single-facilities-box" data-cue="zoomIn">
                                <div className="facilities-icon">
                                    <img src="assets/images/home3/facilities-icon-4.png" alt="" />
                                </div>
                                <div className="facilities-content">
                                    <h4>Room Service</h4>
                                    <p>Located Time Square, Apartment Hotel Private Retreat The Heart World Most Iconic Apartment Services experienced </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="single-facilities-box" data-cue="zoomIn">
                                <div className="facilities-icon">
                                    <img src="assets/images/home3/facilities-icon-5.png" alt="" />
                                </div>
                                <div className="facilities-content">
                                    <h4>Breakfast</h4>
                                    <p>Located Time Square, Apartment Hotel Private Retreat The Heart World Most Iconic Apartment Services experienced </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="single-facilities-box" data-cue="zoomIn">
                                <div className="facilities-icon">
                                    <img src="assets/images/home3/facilities-icon-6.png" alt="" />
                                </div>
                                <div className="facilities-content">
                                    <h4>Luggage Store</h4>
                                    <p>Located Time Square, Apartment Hotel Private Retreat The Heart World Most Iconic Apartment Services experienced </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="feature-area">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 text-center">
                            <div className="section-title center" data-cue="zoomIn">
                                <div className="section-thumb">
                                    <img src="assets/images/home-1/section-shape1.png" alt="" />
                                </div>
                                <h1>Hotel’S Facilities</h1>
                                <p className="section-desc-1">Proactively morph optimal infomediaries rather than accurate expertise. Intrinsicly
                                    progressive resources rather than resource-leveling</p>
                            </div>
                        </div>
                    </div>
                    {/* <div className="row" data-cue="zoomIn">
                        <div className="col-lg-2 col-md-4 col-sm-6">
                            <div className="single-feature-box">
                                <div className="feature-icon">
                                    <img src="assets/images/home-1/feature-1.png" alt="" />
                                </div>
                                <div className="feature-content">
                                    <h4>Room Services</h4>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-2 col-md-4 col-sm-6">
                            <div className="single-feature-box">
                                <div className="feature-icon">
                                    <img src="assets/images/home-1/feature-2.png" alt="" />
                                </div>
                                <div className="feature-content">
                                    <h4>Wi-Fi Internet</h4>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-2 col-md-4 col-sm-6">
                            <div className="single-feature-box">
                                <div className="feature-icon">
                                    <img src="assets/images/home-1/feature-3.png" alt="" />
                                </div>
                                <div className="feature-content">
                                    <h4>Smart Key</h4>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-2 col-md-4 col-sm-6">
                            <div className="single-feature-box">
                                <div className="feature-icon">
                                    <img src="assets/images/home-1/feature-4.png" alt="" />
                                </div>
                                <div className="feature-content">
                                    <h4>Breakfast</h4>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-2 col-md-4 col-sm-6">
                            <div className="single-feature-box">
                                <div className="feature-icon">
                                    <img src="assets/images/home-1/feature-5.png" alt="" />
                                </div>
                                <div className="feature-content">
                                    <h4>Swimming Pool</h4>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-2 col-md-4 col-sm-6">
                            <div className="single-feature-box">
                                <div className="feature-icon">
                                    <img src="assets/images/home-1/feature-2.png" alt="" />
                                </div>
                                <div className="feature-content">
                                    <h4>Wi-Fi Internet</h4>
                                </div>
                            </div>
                        </div>
                    </div> */}
                </div>
            </div>

            <div className="call-do-action-area">
                <div className="container">
                    <div className="row align-items-center call-do-action-bg">
                        <div className="col-lg-6 col-md-12">
                            <div className="section-title two" data-cue="zoomIn">
                                <h4>Manager</h4>
                                <h1>Luxury Best Hotel In</h1>
                                <h1>Californta, Usa</h1>
                                <p className="section-desc-2">Rapidiously myocardinate cross-platform intellectual capital after
                                    model. Appropriately create interactive infrastructures after main
                                    Holisticly facilitate stand-alone inframe.</p>
                            </div>
                            <div className="call-do-action-text" data-cue="zoomIn">
                                <a href="#">“ Model. Appropriately create interactive infrastructures after main
                                    Holisticly facilitate stand-alone inframe of the world ”</a>
                            </div>
                            <div className="call-do-action-author" data-cue="zoomIn">
                                <div className="call-do-action-author-thumb">
                                    <img src="assets/images/home-1/call-do-action-img.png" alt="" />
                                </div>
                                <div className="call-do-action-content">
                                    <h4>John D. Alexon</h4>
                                    <p>Manger</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12">
                            <div className="call-do-action-video" data-cues="zoomIn">
                                <a className="video-vemo-icon venobox vbox-item" data-vbtype="youtube" data-autoplay="true" href="https://www.youtube.com/watch?v=e6R6VsgD8yQ&amp;t=179s"><i className="bi bi-play"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="team-area">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 text-center">
                            <div className="section-title center inner" data-cue="zoomIn">
                                <div className="section-thumb">
                                    <img src="assets/images/home-1/section-shape1.png" alt="" />
                                </div>
                                <h1>Meet The Exper Members</h1>
                                <p className="section-desc-1">Proactively morph optimal infomediaries rather than accurate expertise. Intrinsicly
                                    progressive resources rather than resource-leveling</p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-4 col-md-6">
                            <div className="single-team-box" data-cue="zoomIn">
                                <div className="team-thumb">
                                    <img src="assets/images/team/team-1.jpg" alt="" />
                                </div>
                                <div className="team-content">
                                    <h4>Valentina Kerry</h4>
                                    <p>General Manager</p>
                                </div>
                                <div className="team-contact-info">
                                    <div className="team-social-info">
                                        <ul>
                                            <li><a href="#"><i className="fab fa-facebook-f"></i></a></li>
                                            <li><a href="#"><i className="fab fa-twitter"></i></a></li>
                                            <li><a href="#"><i className="fab fa-linkedin-in"></i></a></li>
                                            <li><a href="#"><i className="fab fa-pinterest-p"></i></a></li>
                                        </ul>
                                    </div>
                                    <span>example@gmail.com</span>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="single-team-box" data-cue="zoomIn">
                                <div className="team-thumb">
                                    <img src="assets/images/team/team-2.jpg" alt="" />
                                </div>
                                <div className="team-content">
                                    <h4>Dhola Gai</h4>
                                    <p>General Manager</p>
                                </div>
                                <div className="team-contact-info">
                                    <div className="team-social-info">
                                        <ul>
                                            <li><a href="#"><i className="fab fa-facebook-f"></i></a></li>
                                            <li><a href="#"><i className="fab fa-twitter"></i></a></li>
                                            <li><a href="#"><i className="fab fa-linkedin-in"></i></a></li>
                                            <li><a href="#"><i className="fab fa-pinterest-p"></i></a></li>
                                        </ul>
                                    </div>
                                    <span>example@gmail.com</span>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="single-team-box" data-cue="zoomIn">
                                <div className="team-thumb">
                                    <img src="assets/images/team/team-3.jpg" alt="" />
                                </div>
                                <div className="team-content">
                                    <h4>Samantha Shen</h4>
                                    <p>General Manager</p>
                                </div>
                                <div className="team-contact-info">
                                    <div className="team-social-info">
                                        <ul>
                                            <li><a href="#"><i className="fab fa-facebook-f"></i></a></li>
                                            <li><a href="#"><i className="fab fa-twitter"></i></a></li>
                                            <li><a href="#"><i className="fab fa-linkedin-in"></i></a></li>
                                            <li><a href="#"><i className="fab fa-pinterest-p"></i></a></li>
                                        </ul>
                                    </div>
                                    <span>example@gmail.com</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default About