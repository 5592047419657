import About from "../pages/About";
import Contact from "../pages/Contact";
import Home from "../pages/Home";
import RoomDetail from "../pages/RoomDetail";
import Rooms from "../pages/Rooms";
import ServiceDetail from "../pages/ServiceDetail";
import Gym from "../pages/services/Gym";
import Restaurant from "../pages/services/Restaurant";
import SwimmingPool from "../pages/services/SwimmingPool";

const publicRoutes = [
  {
    path: "/",
    component: <Home />,
  },
  {
    path: "/rooms",
    component: <Rooms />,
  },
  {
    path: "/room-detail",
    component: <RoomDetail />,
  },
  {
    path: "/about",
    component: <About />,
  },
  {
    path: "/contact",
    component: <Contact />,
  },
  {
    path: "/service-detail",
    component: <ServiceDetail />,
  },
  {
    path: "/services/gym",
    component: <Gym />,
  },
  {
    path: "/services/swimming-pool",
    component: <SwimmingPool />,
  },
  {
    path: "/services/restaurant",
    component: <Restaurant />,
  },

  // {
  //   path: "/planning-information",
  //   component: <PlanningInformation />,
  // },
];

export { publicRoutes };
