import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

function Home() {
  return (
    <div>
      {/* <video autoPlay muted loop className="background-video">
        <source
          src="assets/images/app-images/hillview-hotel-video.mp4"
          type="video/mp4"
        />
        Your browser does not support the video tag.
      </video> */}
      <div className="video-container tw-hidden md:tw-block">
        <video autoPlay muted loop className="background-video">
          <source
            src="assets/images/app-images/hillview-hotel-video.mp4"
            type="video/mp4"
          />
          Your browser does not support the video tag.
        </video>
      </div>

      <div className="md:tw-hidden">
        <OwlCarousel items={1}>
          <div
            className="hero-area home-1 align-items-center d-flex"
            style={{ height: "100vh" }}
          >
            <div className="container">
              <div className="row align-items-center">
                <div className="col-lg-12">
                  <div className="hotel-rating">
                    {/* <ul>
                      <li>
                        <i className="bi bi-star-fill"></i>
                      </li>
                      <li>
                        <i className="bi bi-star-fill"></i>
                      </li>
                      <li>
                        <i className="bi bi-star-fill"></i>
                      </li>
                      <li>
                        <i className="bi bi-star-fill"></i>
                      </li>
                      <li>
                        <i className="bi bi-star-fill"></i>
                      </li>
                    </ul> */}
                  </div>
                  <div className="hero-content">
                    <h4 style={{ zIndex: 99 }}>THE ULTIMATE EXPERIENCE</h4>
                    <h1 className="tw-text-white">
                      Live Life & Enjoy The Great
                    </h1>
                    <h1>Moments With Us</h1>
                  </div>
                  <div className="luxury-button">
                    <a href="#target-section">Discover More</a>
                  </div>
                  <div className="hero-contact">
                    {/* <a href="#">
                      <i className="bi bi-telephone-fill"></i>+980 123 4567 890
                    </a> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="hero-area home-1 style-two align-items-center d-flex"
            style={{ height: "100vh" }}
          >
            <div className="container">
              <div className="row align-items-center">
                <div className="col-lg-12">
                  <div className="hotel-rating">
                    {/* <ul>
                      <li>
                        <i className="bi bi-star-fill"></i>
                      </li>
                      <li>
                        <i className="bi bi-star-fill"></i>
                      </li>
                      <li>
                        <i className="bi bi-star-fill"></i>
                      </li>
                      <li>
                        <i className="bi bi-star-fill"></i>
                      </li>
                      <li>
                        <i className="bi bi-star-fill"></i>
                      </li>
                    </ul> */}
                  </div>
                  <div className="hero-content">
                    <h4>THE ULTIMATE EXPERIENCE</h4>
                    <h1>Live Life & Enjoy The Great</h1>
                    <h1>Moments With Us</h1>
                  </div>
                  <div className="luxury-button">
                    <a href="#target-section">Discover More</a>
                  </div>
                  <div className="hero-contact">
                    {/* <a href="#">
                      <i className="bi bi-telephone-fill"></i>+980 123 4567 890
                    </a> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </OwlCarousel>

        {/* <div className="hero-slider owl-carousel">
          
        </div> */}
      </div>

      {/* booking component */}
      {/* <div className="booking-area home-1">
                <div className="container">
                    <form action="https://formspree.io/f/myyleorq" method="POST" id="dreamit-form">
                        <div className="row add-bg align-items-center">
                            <div className="booking-input-box">
                                <h4>Check In</h4>
                                <input type="date" required />
                            </div>
                            <div className="booking-input-box">
                                <h4>Check Out</h4>
                                <input type="date" required />
                            </div>
                            <div className="booking-input-box">
                                <h4>Rooms</h4>
                                <select name="place" id="place">
                                    <option value="saab">01 Rooms</option>
                                    <option value="opel">02 Rooms</option>
                                    <option value="audi">03 Rooms</option>
                                    <option value="audi">04 Rooms</option>
                                </select>
                            </div>
                            <div className="booking-input-box upper">
                                <h4>Guests</h4>
                                <select name="place" id="place">
                                    <option value="saab">01 Adult, 0 Child</option>
                                    <option value="opel">02 Adult, 1 Child</option>
                                    <option value="audi">02 Adult, 2 Child</option>
                                    <option value="audi">02 Adult, 3 Child</option>
                                </select>
                            </div>
                            <div className="booking-button">
                                <button type="submit">Book Now</button>
                            </div>
                        </div>
                    </form>
                    <div id="status"></div>
                </div>
            </div> */}

      <div className="about-area" id="target-section">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-12">
              <div className="row" data-cue="zoomIn">
                {/* <OwlCarousel items={1}>
                  <div className="col-md-12">
                    <div className="about-thumb">
                      <img
                        src="assets/images/app-images/bedroom1.jpg"
                        style={{ height: "500px", objectFit: "cover" }}
                        alt=""
                      />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="about-thumb">
                      <img
                        src="assets/images/app-images/passage.jpg"
                        style={{ height: "500px", objectFit: "cover" }}
                        alt=""
                      />
                    </div>
                  </div>
                </OwlCarousel> */}
                <OwlCarousel
                  items={1}
                  loop={true}
                  autoplay={true}
                  autoplayTimeout={5000}
                  nav={true}
                  dots={true}
                >
                  <div className="col-md-12">
                    <div className="about-thumb">
                      <img
                        src="assets/images/app-images/bedroom1.jpg"
                        style={{ height: "500px", objectFit: "cover" }}
                        alt=""
                      />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="about-thumb">
                      <img
                        src="assets/images/app-images/passage.jpg"
                        style={{ height: "500px", objectFit: "cover" }}
                        alt=""
                      />
                    </div>
                  </div>
                </OwlCarousel>
              </div>
            </div>
            <div className="col-lg-6 col-md-12  upper">
              <div className="section-title two" data-cue="zoomIn">
                <h4>The Ultimate Experience</h4>
                <h1>Live Life & Enjoy</h1>
                <h1>The Great Moments</h1>
                <h1>With Us</h1>
                <p className="section-desc-2">
                  Rapidiously myocardinate cross-platform intellectual capital
                  after marketing model. Appropriately create interactive
                  infrastructures after maintainable are Holisticly facilitate
                  stand-alone inframe Compellingly create premier open data
                  through economically.
                </p>
              </div>
              <div className="about-conuter-box" data-cue="zoomIn">
                <div className="about-counter-content">
                  <h4 className="counter">250</h4>
                  <span>+</span>
                  <p>Luxury Rooms</p>
                </div>
              </div>
              <div className="about-conuter-box" data-cue="zoomIn">
                <div className="about-counter-content">
                  <h4 className="counter">4.9</h4>
                  <p>Coustomer Ratings</p>
                </div>
              </div>
              <div className="animation-bar" data-cue="zoomIn"></div>
              <div className="luxury-button" data-cue="zoomIn">
                <a href="about.html">Take A Tour</a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="room-title-area">
        <div className="container">
          <div className="row">
            <div className="col-md-12 text-center">
              <div className="section-title center" data-cue="zoomIn">
                {/* <div className="section-thumb">
                  <img src="assets/images/home-1/section-shape1.png" alt="" />
                </div> */}
                <h1>Hillview&apos;s Rooms & Suites</h1>
                <p className="section-desc-1">
                  Proactively morph optimal infomediaries rather than accurate
                  expertise. Intrinsicly progressive resources rather than
                  resource-leveling
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="room-area">
        <div className="container">
          <div className="row margin-top" data-cue="zoomIn">
            <OwlCarousel
              items={3}
              responsive={{
                0: {
                  items: 1, // 1 item per view on mobile
                },
                768: {
                  items: 3, // 3 items per view on larger screens
                },
              }}
            >
              <div className="col-lg-12">
                <div className="room-single-box">
                  <div className="room-thumb">
                    <img src="assets/images/rooms/executive-1.jpg" style={{ width: '410px', height: '320px' }} alt="" />
                    <div className="room-details-button">
                      <a href="/rooms">
                        View Details<i className="bi bi-arrow-right"></i>
                      </a>
                    </div>
                  </div>
                  <div className="room-pricing">
                    <span className="dolar">#65,000</span>
                    <span>Night</span>
                  </div>
                  <div className="room-content">
                    <h4>Luxury Room</h4>
                    <a href="room.html">Executive Room</a>
                    <p>1500 SQ.FT/Rooms</p>
                  </div>
                  <div className="room-bottom">
                    <div className="room-bottom-icon">
                      <span>
                        <img
                          src="assets/images/home-1/room-bottom-icon.png"
                          style={{ width: "15px", height: "15px" }}
                          alt=""
                        />
                        1 King Bed
                      </span>
                    </div>
                    <div className="coustomar-rating">
                      <ul>
                        <li>
                          <i className="bi bi-star-fill"></i>
                        </li>
                        <li>
                          <i className="bi bi-star-fill"></i>
                        </li>
                        <li>
                          <i className="bi bi-star-fill"></i>
                        </li>
                        <li>
                          <i className="bi bi-star-fill"></i>
                        </li>
                        <li>
                          <i className="bi bi-star-half"></i>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-12">
                <div className="room-single-box">
                  <div className="room-thumb">
                    <img src="assets/images/rooms/super-deluxe-1.jpg" style={{ width: '410px', height: '320px' }} alt="" />
                    <div className="room-details-button">
                      <a href="/rooms">
                        View Details<i className="bi bi-arrow-right"></i>
                      </a>
                    </div>
                  </div>
                  <div className="room-pricing">
                    <span className="dolar">#40,000</span>
                    <span>Night</span>
                  </div>
                  <div className="room-content">
                    <h4>Luxury Room</h4>
                    <a href="room.html">Super Deluxe Room</a>
                    <p>1500 SQ.FT/Rooms</p>
                  </div>
                  <div className="room-bottom">
                    <div className="room-bottom-icon">
                      <span>
                        <img
                          src="assets/images/home-1/room-bottom-icon.png"
                          style={{ width: "15px", height: "15px" }}
                          alt=""
                        />
                        1 King Bed
                      </span>
                    </div>
                    <div className="coustomar-rating">
                      <ul>
                        <li>
                          <i className="bi bi-star-fill"></i>
                        </li>
                        <li>
                          <i className="bi bi-star-fill"></i>
                        </li>
                        <li>
                          <i className="bi bi-star-fill"></i>
                        </li>
                        <li>
                          <i className="bi bi-star-fill"></i>
                        </li>
                        <li>
                          <i className="bi bi-star-fill"></i>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-12">
                <div className="room-single-box">
                  <div className="room-thumb">
                    <img src="assets/images/rooms/deluxe-1.jpg" style={{ width: '410px', height: '320px' }} alt="" />
                    <div className="room-details-button">
                      <a href="/rooms">
                        View Details<i className="bi bi-arrow-right"></i>
                      </a>
                    </div>
                  </div>
                  <div className="room-pricing">
                    <span className="dolar">#30,000</span>
                    <span>Night</span>
                  </div>
                  <div className="room-content">
                    <h4>Luxury Room</h4>
                    <a href="room.html">Deluxe Room</a>
                    <p>1500 SQ.FT/Rooms</p>
                  </div>
                  <div className="room-bottom">
                    <div className="room-bottom-icon">
                      <span>
                        <img
                          src="assets/images/home-1/room-bottom-icon.png"
                          style={{ width: "15px", height: "15px" }}
                          alt=""
                        />
                        1 King Bed
                      </span>
                    </div>
                    <div className="coustomar-rating">
                      <ul>
                        <li>
                          <i className="bi bi-star-fill"></i>
                        </li>
                        <li>
                          <i className="bi bi-star-fill"></i>
                        </li>
                        <li>
                          <i className="bi bi-star-fill"></i>
                        </li>
                        <li>
                          <i className="bi bi-star-fill"></i>
                        </li>
                        <li>
                          <i className="bi bi-star"></i>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-12">
                <div className="room-single-box">
                  <div className="room-thumb">
                    <img src="assets/images/home-1/room-1.jpg" alt="" />
                    <div className="room-details-button">
                      <a href="/rooms">
                        View Details<i className="bi bi-arrow-right"></i>
                      </a>
                    </div>
                  </div>
                  <div className="room-pricing">
                    <span className="dolar">$560</span>
                    <span>Night</span>
                  </div>
                  <div className="room-content">
                    <h4>Luxury Room</h4>
                    <a href="room.html">Delux Family Rooms</a>
                    <p>1500 SQ.FT/Rooms</p>
                  </div>
                  <div className="room-bottom">
                    <div className="room-bottom-icon">
                      <span>
                        <img
                          src="assets/images/home-1/room-bottom-icon.png"
                          style={{ width: "15px", height: "15px" }}
                          alt=""
                        />
                        2 King Bed
                      </span>
                    </div>
                    <div className="coustomar-rating">
                      <ul>
                        <li>
                          <i className="bi bi-star-fill"></i>
                        </li>
                        <li>
                          <i className="bi bi-star-fill"></i>
                        </li>
                        <li>
                          <i className="bi bi-star-fill"></i>
                        </li>
                        <li>
                          <i className="bi bi-star-fill"></i>
                        </li>
                        <li>
                          <i className="bi bi-star-half"></i>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </OwlCarousel>
          </div>
        </div>
      </div>

      <div className="facilities-area home-three">
        <div className="container">
          <div className="row">
            <div className="col-md-12 text-center">
              <div className="section-title center" data-cue="zoomIn">
                <h4>Resote Services</h4>
                <h1>Most Popular Hotel Facilities</h1>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-4 col-md-6">
              <div className="single-facilities-box" data-cue="zoomIn">
                <div className="facilities-icon">
                  <img src="assets/images/home3/facilities-icon-1.png" alt="" />
                </div>
                <div className="facilities-content">
                  <h4>Airport Transfer</h4>
                  <p>
                    Located Time Square, Apartment Hotel Private Retreat The
                    Heart World Most Iconic Apartment Services experienced{" "}
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="single-facilities-box" data-cue="zoomIn">
                <div className="facilities-icon">
                  <img src="assets/images/home3/facilities-icon-2.png" alt="" />
                </div>
                <div className="facilities-content">
                  <h4>Fibre Internet</h4>
                  <p>
                    Located Time Square, Apartment Hotel Private Retreat The
                    Heart World Most Iconic Apartment Services experienced{" "}
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="single-facilities-box" data-cue="zoomIn">
                <div className="facilities-icon">
                  <img src="assets/images/home3/facilities-icon-3.png" alt="" />
                </div>
                <div className="facilities-content">
                  <h4>Swimming Pool</h4>
                  <p>
                    Located Time Square, Apartment Hotel Private Retreat The
                    Heart World Most Iconic Apartment Services experienced{" "}
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="single-facilities-box" data-cue="zoomIn">
                <div className="facilities-icon">
                  <img src="assets/images/home3/facilities-icon-4.png" alt="" />
                </div>
                <div className="facilities-content">
                  <h4>Room Service</h4>
                  <p>
                    Located Time Square, Apartment Hotel Private Retreat The
                    Heart World Most Iconic Apartment Services experienced{" "}
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="single-facilities-box" data-cue="zoomIn">
                <div className="facilities-icon">
                  <img src="assets/images/home3/facilities-icon-5.png" alt="" />
                </div>
                <div className="facilities-content">
                  <h4>Breakfast</h4>
                  <p>
                    Located Time Square, Apartment Hotel Private Retreat The
                    Heart World Most Iconic Apartment Services experienced{" "}
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="single-facilities-box" data-cue="zoomIn">
                <div className="facilities-icon">
                  <img src="assets/images/home3/facilities-icon-6.png" alt="" />
                </div>
                <div className="facilities-content">
                  <h4>Luggage Store</h4>
                  <p>
                    Located Time Square, Apartment Hotel Private Retreat The
                    Heart World Most Iconic Apartment Services experienced{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="facilities-area">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-12">
              <div className="section-title two" data-cue="zoomIn">
                <h4>Facilities</h4>
                <h1>Enjoy Complete & Best</h1>
                <h1>Quality Facilities</h1>
              </div>
            </div>
            <div className="col-lg-6 col-md-12">
              <div className="luxury-button" data-cue="zoomIn">
                <a href="services/gym">View More item</a>
              </div>
            </div>
          </div>
          <div className="row add-boder">
            <div className="col-lg-6 col-md-6">
              <div className="single-facilities-images-box" data-cue="zoomIn">
                <div className="facilities-thumb">
                  <img
                    src="assets/images/home-1/facilities-thumb-1.jpg"
                    alt=""
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-6">
              <div className="single-facilities-box" data-cue="zoomIn">
                <div className="facilities-content">
                  <h4>Gym</h4>
                  <h1>Gym Training Grounds</h1>
                  <p>
                    Rapidiously myocardinate cross-platform intellectual capital
                    after model. Appropriately create interactive
                    infrastructures after are Holisticly facilitate stand-alone
                  </p>
                  <a className="facilities-button" href="services/gym">
                    <i className="bi bi-arrow-right"></i>
                  </a>
                </div>
                <div className="facilities-number">
                  <h1>01</h1>
                </div>
              </div>
            </div>
          </div>

          <div className="row add-boder">
            <div className="col-lg-6 col-md-6">
              <div className="single-facilities-box two" data-cue="zoomIn">
                <div className="facilities-content">
                  <h4>Swimming Pool</h4>
                  <h1>Indoor Swimming Pool</h1>
                  <p>
                    Rapidiously myocardinate cross-platform intellectual capital
                    after model. Appropriately create interactive
                    infrastructures after are Holisticly facilitate
                    stand-aloness
                  </p>
                  <a
                    className="facilities-button"
                    href="services/swimming-pool"
                  >
                    <i className="bi bi-arrow-right"></i>
                  </a>
                </div>
                <div className="facilities-number two">
                  <h1>02</h1>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-6">
              <div className="single-facilities-images-box" data-cue="zoomIn">
                <div className="facilities-thumb">
                  <img
                    src="assets/images/home-1/facilities-thumb-2.jpg"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="row add-boder">
            <div className="col-lg-6 col-md-6">
              <div className="single-facilities-images-box" data-cue="zoomIn">
                <div className="facilities-thumb">
                  <img
                    src="assets/images/restaurant/restaurant-1.jpg"
                    alt=""
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-6">
              <div className="single-facilities-box" data-cue="zoomIn">
                <div className="facilities-content">
                  <h4>Restaurant</h4>
                  <h1>The Restaurant</h1>
                  <p>
                    Rapidiously myocardinate cross-platform intellectual capital
                    after model. Appropriately create interactive
                    infrastructures after are Holisticly facilitate stand-alone
                  </p>
                  <a className="facilities-button" href="services/restaurant">
                    <i className="bi bi-arrow-right"></i>
                  </a>
                </div>
                <div className="facilities-number">
                  <h1>03</h1>
                </div>
              </div>
            </div>
          </div>
          <div className="row add-boder">
            <div className="col-lg-6 col-md-6">
              <div className="single-facilities-box two" data-cue="zoomIn">
                <div className="facilities-content">
                  <h4>Fitness</h4>
                  <h1>SPA and Parlor Center</h1>
                  <p>
                    Rapidiously myocardinate cross-platform intellectual capital
                    after model. Appropriately create interactive
                    infrastructures after are Holisticly facilitate stand-alone
                  </p>
                  <a className="facilities-button" href="services-details.html">
                    <i className="bi bi-arrow-right"></i>
                  </a>
                </div>
                <div className="facilities-number two">
                  <h1>04</h1>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-6">
              <div className="single-facilities-images-box" data-cue="zoomIn">
                <div className="facilities-thumb">
                  <img
                    src="assets/images/home-1/facilities-thumb-4.jpg"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="offers-area">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="section-title two" data-cue="zoomIn">
                <h4>offers</h4>
                <h1>HillView’s limited period</h1>
                <h1>best offers</h1>
              </div>
            </div>
          </div>
          <div className="row" data-cue="zoomIn">
            <OwlCarousel
              items={3}
              responsive={{
                0: {
                  items: 1, // 1 item per view on mobile
                },
                768: {
                  items: 3, // 3 items per view on larger screens
                },
              }}
            >
              {/* <div className="col-md-12">
                <div className="single-offers-box">
                  <div className="offers-thumb">
                    <img src="assets/images/home-1/offers-4.jpg" alt="" />
                  </div>
                  <div className="offers-content">
                    <a href="room-details-2.html">Junior Suite Room</a>
                  </div>
                  <div className="offers-dollar">
                    <h4>25% off</h4>
                  </div>
                </div>
              </div> */}

              <div className="col-md-12">
                <div className="single-offers-box">
                  <div className="offers-thumb">
                    <img src="assets/images/rooms/executive-1.jpg" style={{ width: '402px', height: '442px' }} alt="" />
                  </div>
                  <div className="offers-content">
                    <a href="/rooms">Executive Room</a>
                  </div>
                  <div className="offers-dollar">
                    <h4>25% off</h4>
                  </div>
                </div>
              </div>
              <div className="col-md-12">
                <div className="single-offers-box">
                  <div className="offers-thumb">
                    <img src="assets/images/rooms/super-deluxe-1.jpg" style={{ width: '402px', height: '442px' }} alt="" />
                  </div>
                  <div className="offers-content">
                    <a href="/rooms">Super Deluxe Room</a>
                  </div>
                  <div className="offers-dollar">
                    <h4>25% off</h4>
                  </div>
                </div>
              </div>
              <div className="col-md-12">
                <div className="single-offers-box">
                  <div className="offers-thumb">
                    <img src="assets/images/rooms/deluxe-2.jpg" style={{ width: '402px', height: '442px' }} alt="" />
                  </div>
                  <div className="offers-content">
                    <a href="room-details-2.html">Deluxe Room</a>
                  </div>
                  <div className="offers-dollar">
                    <h4>25% off</h4>
                  </div>
                </div>
              </div>
            </OwlCarousel>
          </div>
        </div>
      </div>

      <div className="testimonial-area">
        <div className="container">
          <div className="row">
            <div className="col-md-12 text-center">
              <div className="section-title center" data-cue="zoomIn">
                <div className="section-thumb">
                  <img src="assets/images/home-1/section-shape1.png" alt="" />
                </div>
                <h1>Customer’s Testimonial</h1>
                <p className="section-desc-1">
                  Proactively morph optimal infomediaries rather than accurate
                  expertise. Intrinsicly progressive resources rather than
                  resource-leveling
                </p>
              </div>
            </div>
          </div>
          <div className="row" data-cue="zoomIn">
            <OwlCarousel
              items={2}
              responsive={{
                0: {
                  items: 1, // 1 item per view on mobile
                },
                768: {
                  items: 2, // 3 items per view on larger screens
                },
              }}
            >
              <div className="col-lg-12">
                <div className="single-testi-box">
                  <div className="testi-quote-icon">
                    <img src="assets/images/home-1/testi-quote.png" alt="" />
                  </div>
                  <div className="testi-rating">
                    <ul>
                      <li>
                        <i className="bi bi-star-fill"></i>
                      </li>
                      <li>
                        <i className="bi bi-star-fill"></i>
                      </li>
                      <li>
                        <i className="bi bi-star-fill"></i>
                      </li>
                      <li>
                        <i className="bi bi-star-fill"></i>
                      </li>
                      <li>
                        <i className="bi bi-star"></i>
                      </li>
                    </ul>
                  </div>
                  <div className="testi-content">
                    <p>
                      “Professionally repurpose flexible testing procedures via
                      molla in customer service. Dynamically reconceptualize
                      value-added the systems before manufactured products.
                      Enthusiastically envisioneer emerging best”
                    </p>
                  </div>
                  <div className="testi-author">
                    <div className="testi-author-thumb">
                      <img src="assets/images/home-1/testi-author.png" alt="" />
                    </div>
                    <div className="testi-author-title">
                      <h4>John D. Alexon</h4>
                      <p>Manger</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-12">
                <div className="single-testi-box">
                  <div className="testi-quote-icon">
                    <img src="assets/images/home-1/testi-quote.png" alt="" />
                  </div>
                  <div className="testi-rating">
                    <ul>
                      <li>
                        <i className="bi bi-star-fill"></i>
                      </li>
                      <li>
                        <i className="bi bi-star-fill"></i>
                      </li>
                      <li>
                        <i className="bi bi-star-fill"></i>
                      </li>
                      <li>
                        <i className="bi bi-star-fill"></i>
                      </li>
                      <li>
                        <i className="bi bi-star-half"></i>
                      </li>
                    </ul>
                  </div>
                  <div className="testi-content">
                    <p>
                      “Professionally repurpose flexible testing procedures via
                      molla in customer service. Dynamically reconceptualize
                      value-added the systems before manufactured products.
                      Enthusiastically envisioneer emerging best”
                    </p>
                  </div>
                  <div className="testi-author">
                    <div className="testi-author-thumb">
                      <img
                        src="assets/images/home-1/testi-author-2.png"
                        alt=""
                      />
                    </div>
                    <div className="testi-author-title">
                      <h4>Allena Gomez</h4>
                      <p>Manger</p>
                    </div>
                  </div>
                </div>
              </div>
            </OwlCarousel>
          </div>
        </div>
      </div>

      <div className="brand-area" data-cue="zoomIn">
        <div className="container">
          <div className="row">
            <div className="col-lg-4"></div>
            <div className="col-lg-8">
              <div className="row">
                <div className="brand-list owl-carousel">
                  <OwlCarousel
                    items={3}
                    responsive={{
                      0: {
                        items: 1, // 1 item per view on mobile
                      },
                      768: {
                        items: 3, // 3 items per view on larger screens
                      },
                    }}
                  >
                    <div className="col-lg-12">
                      <div className="single-brand-box">
                        <div className="brand-thumb">
                          <img src="assets/images/home-1/brand-1.png" alt="" />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="single-brand-box">
                        <div className="brand-thumb">
                          <img src="assets/images/home-1/brand-2.png" alt="" />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="single-brand-box">
                        <div className="brand-thumb">
                          <img src="assets/images/home-1/brand-3.png" alt="" />
                        </div>
                      </div>
                    </div>
                  </OwlCarousel>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
