import React from "react";
import GymBg from "../../assets/gym-bg.png";
import Lady from "../../assets/gym-3.png";
import Pool from "../../assets/pool.png";
import Yoga from "../../assets/yoga.png";
import PersonalTraining from "../../assets/personal-training.png";
import exercise from "../../assets/exercise.png";
import family from "../../assets/happy.png";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

function Gym() {
  return (
    <div className="tw-bg-black">
      <div
        className="tw-flex tw-items-center tw-justify-center tw-bg-cover tw-bg-center tw-text-red-400 tw-h-screen"
        style={{
          backgroundImage: `url(${GymBg})`,
        }}
      >
        <div className="hero-content tw-text-center">
          <h4>Stay Fit While You Stay with Us</h4>
          <h1>Experience our state-of-the-art </h1>
          <h1>fitness facilities designed for</h1>
          <h1>your wellness.</h1>
        </div>
      </div>

      <div className="tw-flex tw-flex-col-reverse md:tw-flex-row tw-justify-center tw-gap-12 md:tw-gap-56 tw-items-center tw-bg-black tw-pt-24">
        <div>
          <img
            src={Lady}
            alt=""
            className="tw-max-w-full tw-h-auto  tw-object-contain"
          />
        </div>

        <div className="">
          <h4 className="tw-text-white">Fitness</h4>
          <h1 className="tw-text-white tw-mb-8 tw-text-3xl">
            Modern Equipment
          </h1>

          <p className="tw-text-white">
            Lorem ipsum dolor sit amet consectetur. Eleifend condimentum
            <br />
            imperdiet neque lacus phasellus ultrices. Nulla at aenean viverra
            <br />
            donec. Quam massa tellus augue tortor viverra ridiculus
            <br /> enim amet. Eu aenean purus urna ullamcorper vulputate.{" "}
          </p>
        </div>
      </div>

      <div className="tw-flex tw-flex-col-reverse md:tw-flex-row-reverse tw-justify-center tw-gap-12  md:tw-gap-56 tw-items-center tw-bg-black tw-pt-24">
        <div>
          <img
            src={Pool}
            alt=""
            className="tw-max-w-full tw-h-auto  tw-object-contain"
          />
        </div>

        <div className="">
          <h4 className="tw-text-white">Fitness</h4>
          <h1 className="tw-text-white tw-mb-8 tw-text-3xl">
            Modern Equipment
          </h1>

          <p className="tw-text-white">
            Lorem ipsum dolor sit amet consectetur. Eleifend condimentum
            <br />
            imperdiet neque lacus phasellus ultrices. Nulla at aenean viverra
            <br />
            donec. Quam massa tellus augue tortor viverra ridiculus
            <br /> enim amet. Eu aenean purus urna ullamcorper vulputate.{" "}
          </p>
        </div>
      </div>

      <div className="tw-flex tw-flex-col-reverse md:tw-flex-row tw-justify-center tw-gap-12  md:tw-gap-56 tw-items-center tw-bg-black tw-pt-24">
        <div>
          <img
            src={Yoga}
            alt=""
            className="tw-max-w-full tw-h-auto  tw-object-contain"
          />
        </div>

        <div className="">
          <h4 className="tw-text-white">Fitness</h4>
          <h1 className="tw-text-white tw-mb-8 tw-text-3xl">
            Modern Equipment
          </h1>

          <p className="tw-text-white">
            Lorem ipsum dolor sit amet consectetur. Eleifend condimentum
            <br />
            imperdiet neque lacus phasellus ultrices. Nulla at aenean viverra
            <br />
            donec. Quam massa tellus augue tortor viverra ridiculus
            <br /> enim amet. Eu aenean purus urna ullamcorper vulputate.{" "}
          </p>
        </div>
      </div>

      <div className="tw-flex tw-flex-col-reverse md:tw-flex-row-reverse tw-justify-center tw-gap-12  md:tw-gap-56 tw-items-center tw-bg-black tw-pt-24">
        <div>
          <img
            src={PersonalTraining}
            alt=""
            className="tw-max-w-full tw-h-auto  tw-object-contain"
          />
        </div>

        <div className="">
          <h4 className="tw-text-white">Fitness</h4>
          <h1 className="tw-text-white tw-mb-8 tw-text-3xl">
            Modern Equipment
          </h1>

          <p className="tw-text-white">
            Lorem ipsum dolor sit amet consectetur. Eleifend condimentum
            <br />
            imperdiet neque lacus phasellus ultrices. Nulla at aenean viverra
            <br />
            donec. Quam massa tellus augue tortor viverra ridiculus
            <br /> enim amet. Eu aenean purus urna ullamcorper vulputate.{" "}
          </p>
        </div>
      </div>

      <div className="tw-flex tw-flex-col md:tw-flex-row tw-gap-2 tw-items-center tw-w-full tw-h-[40vh] tw-bg-black tw-mt-12">
        <div className="tw-flex-1 tw-h-full">
          <img
            src={Lady}
            alt="Lady"
            className="tw-h-full tw-w-full tw-object-cover"
          />
        </div>
        <div className="tw-flex-1 tw-h-full">
          <img
            src={exercise}
            alt="Exercise"
            className="tw-h-full tw-w-full tw-object-cover"
          />
        </div>
        <div className="tw-flex-1 tw-h-full">
          <img
            src={Pool}
            alt="Yoga"
            className="tw-h-full tw-w-full tw-object-cover"
          />
        </div>
      </div>

      <div className="tw-flex tw-flex-col md:tw-flex-row tw-gap-2 tw-items-center tw-w-full tw-h-[40vh] tw-bg-black">
        <div className="tw-flex-1 tw-h-full">
          <img
            src={Yoga}
            alt="Lady"
            className="tw-h-full tw-w-full tw-object-cover"
          />
        </div>
        <div className="tw-flex tw-h-full">
          <img
            src={family}
            alt="Exercise"
            className="tw-h-full tw-w-full tw-object-cover"
          />
        </div>
        <div className="tw-flex-1 tw-h-full">
          <img
            src={PersonalTraining}
            alt="Yoga"
            className="tw-h-full tw-w-full tw-object-cover"
          />
        </div>
      </div>

      <div className="brand-area" data-cue="zoomIn">
        <div className="container">
          <div className="row">
            <div className="col-lg-4"></div>
            <div className="col-lg-8">
              <div className="row">
                <div className="brand-list owl-carousel">
                  <OwlCarousel
                    items={3}
                    responsive={{
                      0: {
                        items: 1, // 1 item per view on mobile
                      },
                      768: {
                        items: 3, // 3 items per view on larger screens
                      },
                    }}
                  >
                    <div className="col-lg-12">
                      <div className="single-brand-box">
                        <div className="brand-thumb">
                          <img src="assets/images/home-1/brand-1.png" alt="" />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="single-brand-box">
                        <div className="brand-thumb">
                          <img src="assets/images/home-1/brand-2.png" alt="" />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="single-brand-box">
                        <div className="brand-thumb">
                          <img src="assets/images/home-1/brand-3.png" alt="" />
                        </div>
                      </div>
                    </div>
                  </OwlCarousel>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Gym;
