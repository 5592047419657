import React from 'react'

function ServiceDetail() {
    return (
        <>
            <div className="breadcumb-area d-flex align-items-center">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-12">
                            <div className="breacumb-content">
                                <div className="breadcum-title">
                                    <h4>Serivce Details</h4>
                                </div>
                                <ul>
                                    <li><a href="index-2.html">Home</a></li>
                                    <li>/</li>
                                    <li>Services Details</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="service-details">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8">
                            <div className="service-details-thumb">
                                <img src="assets/images/inner/service-details.jpg" alt="" />
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="service-time-list">
                                <div className="service-time-list-content">
                                    <h4>Hours</h4>
                                </div>
                                <div className="service-time-list-item">
                                    <ul>
                                        <li><strong>Breakfast -</strong>7.00 AM to 10.30 AM</li>
                                        <li><strong>Lunch -</strong>1.00 PM to 2.30 PM</li>
                                        <li><strong>Supper -</strong>6.00 PM to 7.00 PM</li>
                                        <li><strong>Dinner -</strong> 8.30 PM to 10.00 PM</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="service-details-content">
                                <div className="services-details-title-box">
                                    <h4>Foods</h4>
                                    <h1>The Restaurent Center</h1>
                                    <p className="magnahu" data-cue="zoomIn">Rapidiously myocardinate cross-platform intellectual capital after marketing model. Appropriately create
                                        interactive infrastructures after maintainable are Holisticly
                                        facilitate stand-alone inframe extend state of the art benefits via web-enabled value.
                                        Completely fabricate extensible infomediaries rather than reliable e-services.
                                        Dramatically whiteboard alternative Conveniently fashion pandemic potentialities
                                        for team driven technologies. Proactively orchestrate robust systems rather than
                                        user-centric vortals. Distinctively seize top-line e-commerce with premier.
                                    </p>
                                </div>
                                <div className="services-details-title-box" data-cue="zoomIn">
                                    <h2>Restaurent Rules</h2>
                                    <p>Professionally deliver fully researched scenarios with turnkey communities. Competently unleash empowered
                                        applications without seamless data. Uniquely under quality outsourcing before transparent relationships.
                                        Efficiently enhance diverse relationships whereas leveraged leverage existing
                                        just in time architectures before economically sound systems. Conveniently administrate
                                    </p>
                                </div>
                                <div className="services-details-title-box" data-cue="zoomIn">
                                    <h2>Dress Code</h2>
                                    <p>Professionally deliver fully researched scenarios with turnkey communities competently</p>
                                    <div className="service-details-item">
                                        <ul>
                                            <li><i className="bi bi-check2"></i>Quickly generate bricks-and-clicks</li>
                                            <li><i className="bi bi-check2"></i>Interactively cultivate visionary platforms</li>
                                            <li><i className="bi bi-check2"></i>Energistically envisioneer resource</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="services-details-title-box" data-cue="zoomIn">
                                    <h2>Terrace</h2>
                                    <p>Open the drinks only maintain restaurent rules and regulations below</p>
                                    <div className="service-details-item">
                                        <ul>
                                            <li><i className="bi bi-check2"></i>Quickly generate bricks-and-clicks</li>
                                            <li><i className="bi bi-check2"></i>Interactively cultivate visionary platforms</li>
                                            <li><i className="bi bi-check2"></i>Energistically envisioneer resource</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="food-menu-area">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 text-center">
                            <div className="section-title center inner" data-cue="zoomIn">
                                <div className="section-thumb">
                                    <img src="assets/images/home-1/section-shape1.png" alt="" />
                                </div>
                                <h1>Restaurent Food Menu</h1>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="food-tab-item">
                                <div className="food-tab-menu" data-cue="zoomIn">
                                    <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
                                        <li className="nav-item" role="presentation">
                                            <button className="nav-link active" id="breakfast" data-bs-toggle="pill" data-bs-target="#pills-breackfast" type="button" role="tab" aria-controls="pills-home" aria-selected="true">Breakfast</button>
                                        </li>
                                        <li className="nav-item" role="presentation">
                                            <button className="nav-link" id="lunch" data-bs-toggle="pill" data-bs-target="#pills-lunch" type="button" role="tab" aria-controls="pills-profile" aria-selected="false">Lunch</button>
                                        </li>
                                        <li className="nav-item" role="presentation">
                                            <button className="nav-link" id="supper" data-bs-toggle="pill" data-bs-target="#pills-supper" type="button" role="tab" aria-controls="pills-contact" aria-selected="false">Supper</button>
                                        </li>
                                        <li className="nav-item" role="presentation">
                                            <button className="nav-link" id="dinner" data-bs-toggle="pill" data-bs-target="#pills-dinner" type="button" role="tab" aria-controls="pills-contact" aria-selected="false">Dinner</button>
                                        </li>
                                    </ul>
                                </div>
                                <div className="tab-content" id="pills-tabContent">
                                    <div className="tab-pane fade show active" id="pills-breackfast" role="tabpanel" aria-labelledby="breakfast" tabIndex={0}>
                                        <div className="row">
                                            <div className="col-lg-6 col-md-12">
                                                <div className="single-food-item-box" data-cue="zoomIn">
                                                    <div className="food-thumb">
                                                        <img src="assets/images/inner/food-1.jpg" alt="" />
                                                    </div>
                                                    <div className="food-item-content">
                                                        <h4>Fride Rice</h4>
                                                        <span>$49.00</span>
                                                        <p>Professionally deliver fully researched scenarios with
                                                            turnkey communities competently</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-12">
                                                <div className="single-food-item-box" data-cue="zoomIn">
                                                    <div className="food-thumb">
                                                        <img src="assets/images/inner/food-2.jpg" alt="" />
                                                    </div>
                                                    <div className="food-item-content">
                                                        <h4>Chicken</h4>
                                                        <span>$59.00</span>
                                                        <p>Professionally deliver fully researched scenarios with
                                                            turnkey communities competently</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-12">
                                                <div className="single-food-item-box" data-cue="zoomIn">
                                                    <div className="food-thumb">
                                                        <img src="assets/images/inner/food-3.jpg" alt="" />
                                                    </div>
                                                    <div className="food-item-content">
                                                        <h4>Fry Fishes</h4>
                                                        <span>$30.00</span>
                                                        <p>Professionally deliver fully researched scenarios with
                                                            turnkey communities competently</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-12">
                                                <div className="single-food-item-box" data-cue="zoomIn">
                                                    <div className="food-thumb">
                                                        <img src="assets/images/inner/food-4.jpg" alt="" />
                                                    </div>
                                                    <div className="food-item-content">
                                                        <h4>Vegetables</h4>
                                                        <span>$35.00</span>
                                                        <p>Professionally deliver fully researched scenarios with
                                                            turnkey communities competently</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="tab-pane fade" id="pills-lunch" role="tabpanel" aria-labelledby="lunch" tabIndex={0}>
                                        <div className="row">
                                            <div className="col-lg-6 col-md-12">
                                                <div className="single-food-item-box" data-cue="zoomIn">
                                                    <div className="food-thumb">
                                                        <img src="assets/images/inner/food-1.jpg" alt="" />
                                                    </div>
                                                    <div className="food-item-content">
                                                        <h4>Fride Rice</h4>
                                                        <span>$49.00</span>
                                                        <p>Professionally deliver fully researched scenarios with
                                                            turnkey communities competently</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-12">
                                                <div className="single-food-item-box" data-cue="zoomIn">
                                                    <div className="food-thumb">
                                                        <img src="assets/images/inner/food-3.jpg" alt="" />
                                                    </div>
                                                    <div className="food-item-content">
                                                        <h4>Fry Fishes</h4>
                                                        <span>$30.00</span>
                                                        <p>Professionally deliver fully researched scenarios with
                                                            turnkey communities competently</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="tab-pane fade" id="pills-supper" role="tabpanel" aria-labelledby="supper" tabIndex={0}>
                                        <div className="row">
                                            <div className="col-lg-6 col-md-12">
                                                <div className="single-food-item-box">
                                                    <div className="food-thumb">
                                                        <img src="assets/images/inner/food-1.jpg" alt="" />
                                                    </div>
                                                    <div className="food-item-content">
                                                        <h4>Fride Rice</h4>
                                                        <span>$49.00</span>
                                                        <p>Professionally deliver fully researched scenarios with
                                                            turnkey communities competently</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-12">
                                                <div className="single-food-item-box">
                                                    <div className="food-thumb">
                                                        <img src="assets/images/inner/food-2.jpg" alt="" />
                                                    </div>
                                                    <div className="food-item-content">
                                                        <h4>Chicken</h4>
                                                        <span>$59.00</span>
                                                        <p>Professionally deliver fully researched scenarios with
                                                            turnkey communities competently</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-12">
                                                <div className="single-food-item-box">
                                                    <div className="food-thumb">
                                                        <img src="assets/images/inner/food-3.jpg" alt="" />
                                                    </div>
                                                    <div className="food-item-content">
                                                        <h4>Fry Fishes</h4>
                                                        <span>$30.00</span>
                                                        <p>Professionally deliver fully researched scenarios with
                                                            turnkey communities competently</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-12">
                                                <div className="single-food-item-box">
                                                    <div className="food-thumb">
                                                        <img src="assets/images/inner/food-4.jpg" alt="" />
                                                    </div>
                                                    <div className="food-item-content">
                                                        <h4>Vegetables</h4>
                                                        <span>$35.00</span>
                                                        <p>Professionally deliver fully researched scenarios with
                                                            turnkey communities competently</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="tab-pane fade" id="pills-dinner" role="tabpanel" aria-labelledby="dinner" tabIndex={0}>
                                        <div className="row">
                                            <div className="col-lg-6 col-md-12">
                                                <div className="single-food-item-box">
                                                    <div className="food-thumb">
                                                        <img src="assets/images/inner/food-4.jpg" alt="" />
                                                    </div>
                                                    <div className="food-item-content">
                                                        <h4>Vegetables</h4>
                                                        <span>$35.00</span>
                                                        <p>Professionally deliver fully researched scenarios with
                                                            turnkey communities competently</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-12">
                                                <div className="single-food-item-box">
                                                    <div className="food-thumb">
                                                        <img src="assets/images/inner/food-2.jpg" alt="" />
                                                    </div>
                                                    <div className="food-item-content">
                                                        <h4>Chicken</h4>
                                                        <span>$59.00</span>
                                                        <p>Professionally deliver fully researched scenarios with
                                                            turnkey communities competently</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </>
    )
}

export default ServiceDetail