import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

function Rooms() {
  return (
    <>
      <div className="breadcumb-area d-flex align-items-center">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-12">
              <div className="breacumb-content">
                <div className="breadcum-title">
                  <h4>Rooms & Suttes</h4>
                </div>
                <ul>
                  <li>
                    <a href="index-2.html">Home</a>
                  </li>
                  <li>/</li>
                  <li>Rooms & Suttes</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="room-area inner">
        <div className="container">
          <div className="row">
            <div className="col-md-12 text-center">
              <div className="section-title center inner" data-cue="zoomIn">
                {/* <div className="section-thumb">
                  <img src="assets/images/home-1/section-shape1.png" alt="" />
                </div> */}
                <h1>HillView’s Rooms & Suites</h1>
                <p className="section-desc-1">
                  Proactively morph optimal infomediaries rather than accurate
                  expertise. Intrinsicly progressive resources rather than
                  resource-leveling
                </p>
              </div>
            </div>
          </div>
          <div className="row align-items-center">
            <div className="col-lg-4 col-md-6">
              <div className="room-single-box" data-cue="zoomIn">
                <div className="room-thumb">
                  <img src="assets/images/rooms/executive-1.jpg" style={{ width: '410px', height: '320px' }} alt="" />
                  <div className="room-details-button">
                    <a href="/rooms">
                      View Details<i className="bi bi-arrow-right"></i>
                    </a>
                  </div>
                </div>
                <div className="room-pricing">
                  <span className="dolar">#65,000</span>
                  <span>Night</span>
                </div>
                <div className="room-content">
                  <h4>Luxury Room</h4>
                  <a href="/rooms">Executive Room</a>
                  <p>1500 SQ.FT/Rooms</p>
                </div>
                <div className="room-bottom">
                  <div className="room-bottom-icon">
                    <span>
                      <img
                        src="assets/images/home-1/room-bottom-icon.png"
                        alt=""
                      />
                      1 King Bed
                    </span>
                  </div>
                  <div className="coustomar-rating">
                    <ul>
                      <li>
                        <i className="bi bi-star-fill"></i>
                      </li>
                      <li>
                        <i className="bi bi-star-fill"></i>
                      </li>
                      <li>
                        <i className="bi bi-star-fill"></i>
                      </li>
                      <li>
                        <i className="bi bi-star-fill"></i>
                      </li>
                      <li>
                        <i className="bi bi-star-half"></i>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="room-single-box" data-cue="zoomIn">
                <div className="room-thumb">
                  <img src="assets/images/rooms/super-deluxe-1.jpg" style={{ width: '410px', height: '320px' }} alt="" />
                  <div className="room-details-button">
                    <a href="/rooms">
                      View Details<i className="bi bi-arrow-right"></i>
                    </a>
                  </div>
                </div>
                <div className="room-pricing">
                  <span className="dolar">#40,000</span>
                  <span>Night</span>
                </div>
                <div className="room-content">
                  <h4>Luxury Room</h4>
                  <a href="/rooms">Super Deluxe Room</a>
                  <p>1500 SQ.FT/Rooms</p>
                </div>
                <div className="room-bottom">
                  <div className="room-bottom-icon">
                    <span>
                      <img
                        src="assets/images/home-1/room-bottom-icon.png"
                        alt=""
                      />
                      1 King Bed
                    </span>
                  </div>
                  <div className="coustomar-rating">
                    <ul>
                      <li>
                        <i className="bi bi-star-fill"></i>
                      </li>
                      <li>
                        <i className="bi bi-star-fill"></i>
                      </li>
                      <li>
                        <i className="bi bi-star-fill"></i>
                      </li>
                      <li>
                        <i className="bi bi-star-fill"></i>
                      </li>
                      <li>
                        <i className="bi bi-star-fill"></i>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="room-single-box" data-cue="zoomIn">
                <div className="room-thumb">
                  <img src="assets/images/rooms/deluxe-1.jpg" style={{ width: '410px', height: '320px' }} alt="" />
                  <div className="room-details-button">
                    <a href="/rooms">
                      View Details<i className="bi bi-arrow-right"></i>
                    </a>
                  </div>
                </div>
                <div className="room-pricing">
                  <span className="dolar">#30,000</span>
                  <span>Night</span>
                </div>
                <div className="room-content">
                  <h4>Luxury Room</h4>
                  <a href="/rooms">Deluxe Room</a>
                  <p>1500 SQ.FT/Rooms</p>
                </div>
                <div className="room-bottom">
                  <div className="room-bottom-icon">
                    <span>
                      <img
                        src="assets/images/home-1/room-bottom-icon.png"
                        alt=""
                      />
                      1 King Bed
                    </span>
                  </div>
                  <div className="coustomar-rating">
                    <ul>
                      <li>
                        <i className="bi bi-star-fill"></i>
                      </li>
                      <li>
                        <i className="bi bi-star-fill"></i>
                      </li>
                      <li>
                        <i className="bi bi-star-fill"></i>
                      </li>
                      <li>
                        <i className="bi bi-star-fill"></i>
                      </li>
                      <li>
                        <i className="bi bi-star"></i>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="facilities-area inner">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-12">
              <div className="section-title two" data-cue="zoomIn">
                <h4>Luxury Hotel And Resort</h4>
                <h1>Resote’s Extra Facilities</h1>
                <h1>For luxurious life</h1>
              </div>
            </div>
          </div>
          <div className="row border-add">
            <div className="col-lg-4 col-md-12" data-cue="zoomIn">
              <div className="facilities-icon">
                <img src="assets/images/inner/faciliies-icon.png" alt="" />
              </div>
              <div className="facilities-content">
                <h4>SPA & Parlor Center</h4>
                <p>
                  Rapidiously myocardinate cross-platform are model.
                  Appropriately create interactive infrast Holisticly facilitate{" "}
                </p>
                <a href="services-details.html">
                  View Details<i className="bi bi-arrow-right"></i>
                </a>
              </div>
            </div>
            <div className="col-lg-8 col-md-12">
              <div className="row" data-cue="zoomIn">
                {/* <div className="facilities-list owl-carousel"> */}
                <div className="facilities-list">
                  <OwlCarousel
                    items={2}
                    responsive={{
                      0: {
                        items: 1, // 1 item per view on mobile
                      },
                      768: {
                        items: 2, // 3 items per view on larger screens
                      },
                    }}
                  >
                    <div className="col-md-12">
                      <div className="single-facilities-box">
                        <div className="facilities-thumb">
                          <img
                            src="assets/images/inner/facilities-1.jpg"
                            alt=""
                          />
                        </div>
                        <div className="facilities-button">
                          <a href="services-details.html">
                            Gym Training Ground
                            <i className="bi bi-arrow-right-short"></i>
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="single-facilities-box">
                        <div className="facilities-thumb">
                          <img
                            src="assets/images/inner/facilities-2.jpg"
                            alt=""
                          />
                        </div>
                        <div className="facilities-button">
                          <a href="services-details.html">
                            Indoor Swimming Pool
                            <i className="bi bi-arrow-right-short"></i>
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="single-facilities-box">
                        <div className="facilities-thumb">
                          <img
                            src="assets/images/restaurant/restaurant-1.jpg"
                            alt=""
                          />
                        </div>
                        <div className="facilities-button">
                          <a href="services-details.html">
                            Cafe & Restaurent Center
                            <i className="bi bi-arrow-right-short"></i>
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="single-facilities-box">
                        <div className="facilities-thumb">
                          <img
                            src="assets/images/inner/facilities-1.jpg"
                            alt=""
                          />
                        </div>
                        <div className="facilities-button">
                          <a href="services-details.html">
                            Gym Training Ground
                            <i className="bi bi-arrow-right-short"></i>
                          </a>
                        </div>
                      </div>
                    </div>
                  </OwlCarousel>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="contact-area style-two">
        <div className="container">
          <div className="row add-backgroun">
            <div className="col-lg-9 col-md-12">
              <div className="section-title two" data-cue="zoomIn">
                <h4>Contact us</h4>
                <h1>Contact With Us</h1>
                <p className="section-desc-2">
                  Rapidiously myocardinate cross-platform intellectual capital
                  after the model. Appropriately create interactive
                  infrastructures after maintance Holisticly facilitate
                  stand-alone
                </p>
              </div>
              <div className="single-contact-box" data-cue="zoomIn">
                <div className="contact-icon">
                  <i className="bi bi-telephone-fill"></i>
                </div>
                <div className="contact-title">
                  <h4>Call Us Now</h4>
                  <p>08095777444, 08096777444, 07073431162</p>
                </div>
              </div>
              <div className="single-contact-box" data-cue="zoomIn">
                <div className="contact-icon">
                  <i className="bi bi-envelope"></i>
                </div>
                <div className="contact-title">
                  <h4>Email</h4>
                  <p>hillviewapartment1806@gmail.com</p>
                </div>
              </div>
              <div className="single-contact-box" data-cue="zoomIn">
                <div className="contact-icon">
                  <i className="bi bi-geo-alt-fill"></i>
                </div>
                <div className="contact-title">
                  <h4>Our Locations</h4>
                  <p>Plot 1806, Road 1, zone E, Apo Resettlement Abuja FCT</p>
                  {/* <p>Dhaka - 1212</p> */}
                </div>
              </div>
            </div>
            {/* <div className="col-lg-6 col-md-12">
                            <form action="https://formspree.io/f/myyleorq" method="POST" id="dreamit-form">
                                <div className="single-contact-form" data-cue="zoomIn">
                                    <div className="contact-content">
                                        <h4>Get In Touch</h4>
                                    </div>
                                    <div className="single-input-box">
                                        <input type="text" name="Name" placeholder="Your Name" required />
                                    </div>
                                    <div className="single-input-box">
                                        <input type="text" name="Email" placeholder="Enter Your Email" required />
                                    </div>
                                    <div className="single-input-box">
                                        <select name="Subject" id="Subject">
                                            <option value="saab">select Subject</option>
                                            <option value="opel">Luxury Hotel</option>
                                            <option value="audi">Room</option>
                                            <option value="audi">Hottel</option>
                                        </select>
                                    </div>
                                    <div className="single-input-box">
                                        <textarea name="Message" id="Message" placeholder="Write Message"></textarea>
                                    </div>
                                    <div className="single-input-box">
                                        <button type="submit">Sent Message</button>
                                    </div>
                                </div>
                            </form>
                            <div id="status"></div>
                        </div> */}
          </div>
        </div>
      </div>

      {/* <div className="brand-area" data-cue="zoomIn">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4"></div>
                        <div className="col-lg-8">
                            <div className="row">
                                <div className="brand-list owl-carousel">
                                    <div className="col-lg-12">
                                        <div className="single-brand-box">
                                            <div className="brand-thumb">
                                                <img src="assets/images/home-1/brand-1.png" alt="" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-12">
                                        <div className="single-brand-box">
                                            <div className="brand-thumb">
                                                <img src="assets/images/home-1/brand-2.png" alt="" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-12">
                                        <div className="single-brand-box">
                                            <div className="brand-thumb">
                                                <img src="assets/images/home-1/brand-3.png" alt="" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
    </>
  );
}

export default Rooms;
